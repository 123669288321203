import React, { Component } from 'react';
import FlightFareSummaryComponent from '../booking/FlightFareSummaryComponent';
import { dateFormat, currencyFormat, getCurrentDate } from '../../../shared/commonHelper';
import * as flightHelper from '../../../shared/flightHelper';
import { getFare, formatDuration } from "../../../shared/flightHelper";
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import DropBookingComponent from './DropBookingComponent';

class BookingConfirmComponent extends Component {
    constructor(){
        super();
        this.state = {
            showConfirmationPopup : false,
            showKafilaConfirmationPopup : 'show',
            showPriceChangePopup : 'show',
            loadings:false
        }
    }
    componentDidMount() {
        this.props.kafilaSeatState([])
        if (this.props.location.state.bookingType === 'confirm') {
            this.props.myAccountAction();
        }
        if (this.props.location.state.bookingType === 'drop') {
            if(this.props.location.state.searchData && this.props.location.state.searchData.api_source === 'tbo'){
                let data = {
                    "trace_id":this.props.location.state && this.props.location.state.trace_id,
                    "result_index":this.props.location.state.searchData && this.props.location.state.searchData.result_index,
                    "segments":[
                        {
                            "origin":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].origin.airport.airport_code,
                            "destination":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].destination.airport.airport_code,
                            "preferred_departure_time":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].origin.dep_time,
                            "preferred_arrival_time":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].destination.arr_time
                        }
                    ]
                }
                this.props.getFlightFareRuleDetails(data);
            } 
            if(this.props.location.state.searchData && this.props.location.state.searchData.api_source === 'omairiq'){
                let data = {
                    "result_index":this.props.location.state.searchData && this.props.location.state.searchData.result_index,
                    "trace_id":this.props.location.state && this.props.location.state.trace_id,
                    "segments":[
                        {
                            "origin":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].origin.airport.airport_code,
                            "destination":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].destination.airport.airport_code,
                        }
                    ]
                }
                this.props.getAirIQFlightFareRuleDetails(data);
            } 
            if(this.props.location.state.searchData && this.props.location.state.searchData.api_source === 'gofly'){
                let data = {
                    "result_index":this.props.location.state.searchData && this.props.location.state.searchData.result_index,
                    "trace_id":this.props.location.state && this.props.location.state.trace_id,
                    "segments":[
                        {
                            "origin":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].origin.airport.airport_code,
                            "destination":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].destination.airport.airport_code,
                        }
                    ]
                }
                this.props.getGoflyFlightFareRuleDetails(data);
            } 
            if(this.props.location.state.searchData && this.props.location.state.searchData.api_source === 'efly'){
                let data = {
                    "result_index":this.props.location.state.searchData && this.props.location.state.searchData.result_index,
                    "trace_id":this.props.location.state && this.props.location.state.trace_id,
                    "segments":[
                        {
                            "origin":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].origin.airport.airport_code,
                            "destination":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].destination.airport.airport_code,
                        }
                    ]
                }
                this.props.geteflyFlightFareRuleDetails(data);
            } 
            if(this.props.location.state.searchData && this.props.location.state.searchData.api_source === 'satkar'){
                let data = {
                    "result_index":this.props.location.state.searchData && this.props.location.state.searchData.result_index,
                    "trace_id":this.props.location.state && this.props.location.state.trace_id,
                    "segments":[
                        {
                            "origin":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].origin.airport.airport_code,
                            "destination":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].destination.airport.airport_code,
                        }
                    ]
                }
                this.props.getsatkarFlightFareRuleDetails(data);
            } 
            if(this.props.location.state.searchData && this.props.location.state.searchData.api_source === 'fbq'){
                let data = {
                    "result_index":this.props.location.state.searchData && this.props.location.state.searchData.result_index,
                    "trace_id":this.props.location.state && this.props.location.state.trace_id,
                    "segments":[
                        {
                            "origin":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].origin.airport.airport_code,
                            "destination":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].destination.airport.airport_code,
                        }
                    ]
                }
                this.props.getFareBoutiqueFlightFareRuleDetails(data);
            } 
            if(this.props.location.state.searchData && this.props.location.state.searchData.api_source === 'tripjack'){
                let origin = this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].origin.airport.airport_code;
                let destination = this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].destination.airport.airport_code;
                let FareRuleDetailsCredential = { "uid": this.props.location.state.searchData.uid, "flowType": "SEARCH", "origin": origin, "destination": destination }
                this.props.getTripJackFlightFareRuleDetails(FareRuleDetailsCredential);
            }
            if(this.props.location.state.searchData && this.props.location.state.searchData.api_source === 'riya_travel'){
                let data = {
                    "result_index":this.props.location.state.searchData && this.props.location.state.searchData.result_index,
                    "trace_id":this.props.location.state && this.props.location.state.trace_id,
                    "segments":[
                        {
                            "origin":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].origin.airport.airport_code,
                            "destination":this.props.location.state.searchData && this.props.location.state.searchData.segments && this.props.location.state.searchData.segments[0][0].destination.airport.airport_code,
                        }
                    ]
                }
                this.props.getRiyaFlightFareRuleDetails(data);
            }
        }
    }

    hidePopupModal = () =>{
        this.setState({ showKafilaConfirmationPopup : 'hide' })
    }
    hidePriceChangePopupModal = () =>{
        this.setState({ showPriceChangePopup : 'hide' })
    }

    handleConfirmationPopup = (event,action)=>{
        
		this.setState({
			showConfirmationPopup : event,
			confimationAction : action
		})		
	}

	handleConfimationAction = (bookingData,successCb,errorCb,successCb1) =>{
     
		this.setState({
			showConfirmationPopup : false,
            loadings:true
        });
        if(this.state.confimationAction === 'tripjack-hold-ticket-booking')
        {
            const bookDetails=(response)=>
            {
                this.props.tripJackBookDetails(bookingData,successCb1,errorCb);
                
            }

            const bookingConfirm=(response)=>
            {
                let bookConfirmRequest  = {
                    "tripjack_booking_id" : bookingData.tripjack_booking_id,
                    "booking_id": bookingData.booking_id,   
                    "offered_fare": bookingData.offer_fare_old,         
                }
                this.props.tripJackBookConfirm(bookConfirmRequest,bookDetails,errorCb)
            }
            let validateData = {
                "booking_id": bookingData.booking_id,
                "tripjack_booking_id": bookingData.tripjack_booking_id
            }
            this.props.validateTripJackTicket(validateData, bookingConfirm, errorCb)
        }else if(this.state.confimationAction === 'riya-hold-ticket-booking'){
            let confirmData = {
                "riya_booking_id" : bookingData.riya_booking_id,
                "booking_id" : bookingData.booking_id,
                "price_modified" : bookingData.price_modified,
                "pnr" : bookingData.pnr,
               "riya_pnr" : bookingData.riya_pnr
            }
            const BookDetails = (response) => {
                if (response && response.response && response.response.data && response.response.data.booking_status === "PENDING") {
                    this.props.myAccountAction();
                    this.props.history.push({
                        pathname: "/booking-confirm",
                        state: {
                            data: { tbo_response: response.savedResponse },
                            bookingType: "ticket_pending",
                        },
                    });
                }else{
                    this.props.riyaBookDetails(bookingData, successCb1, errorCb);
                }
            };
    
            this.props.riyaBookConfirm(confirmData, BookDetails, errorCb);
            
        }
        else if(this.state.confimationAction === 'hold-ticket-booking')
        {
            this.props.bookTicket(bookingData, successCb, errorCb)
        }
	}

    printTicket = (data) => {
        let printTicketData = {
            "jf_booking_id": data.details.booking_id,
            "tbo_booking_id": data.details.transaction_no,
            "pnr": data.details.pnr,
            "booking_type": 'online'
        }
        localStorage.setItem("print-ticket-data", JSON.stringify(printTicketData));
        window.open('/print-ticket', "_blank");
    }

    printInvoice = (data) => {
        let printTicketData = {
            "booking_id": data.details.booking_id,
            "tbo_booking_id": data.details.transaction_no,
            "pnr": data.details.pnr,
            "booking_type": 'online'
        }
        localStorage.setItem("print-invoice-data", JSON.stringify(printTicketData));
        window.open('/print-invoice', "_blank");
    }

    outboundBooking = () => {
        this.props.initialState();
        this.props.location.state.searchType = 'round-trip-complete';
        let keysArray = [];
        let formikValues = this.props.outboundPaxInfo.details;
        let tempKeyArr = formikValues && Object.keys(formikValues);
        /* eslint-disable-next-line */
        tempKeyArr && tempKeyArr.map((item, key) => {
            if ((item.match(/baggage_/)) || (item.match(/meal_/))) {
                keysArray.push(item);
            }
        });
        /* eslint-disable-next-line */
        keysArray && keysArray.map((item, key) => {
            formikValues[item] = 0;
            // this.props.setFieldValue(item, 0);
        });
        this.props.saveFlightOutboundPaxInfo(formikValues,this.props.outboundPaxInfo.booking_id,this.props.outboundPaxInfo.outboundPub);
        this.props.location.state.index = 1;
        this.props.location.state.trace_id = (this.props.location.state.data.trace_id && this.props.location.state.data.trace_id !== "null") ? this.props.location.state.data.trace_id : this.props.location.state.trace_id;
        this.props.location.state.param_return=this.props.location.state.param_return
        this.props.location.state.data = this.props.location.state.searchData;
        this.props.setFareQuoteValue(this.props.fareQuoteRound.response);
        this.props.setSSRValue(this.props.ssrDetailsRound.response);
        this.props.setTripJackSeatInfo(this.props.seatInfoRound.response);
        this.props.setTripJackFlightFareRuleDetails(this.props.tripJackRoundFlightFareRuleDetails.response)
        this.props.setAirIQFlightFareRuleDetails(this.props.airIQRoundFlightFareRuleDetails.response)
        this.props.setKafilaFlightFareRuleDetails(this.props.kafilaRoundFlightFareRuleDetails.response ? this.props.kafilaRoundFlightFareRuleDetails.response:this.props.kafilaFlightFareRuleDetails.response)
        this.props.setFlightFareRuleDetails(this.props.roundFlightFareRuleDetails.response)
        //this.props.setRiyaFlightFareRuleDetails(this.props.riyaRoundFlightFareRuleDetails.response)
        this.props.history.push({
            pathname: "/book-ticket",
            state: {
                searchType: 'round-trip-complete',
                trace_id: this.props.location.state.trace_id,
                data: this.props.location.state.searchData,
                index: 1,
                param:this.props.location.state.param_return,
            }
        });

    }

     // Fare Rule Information
     renderFareRule(flightFareRuleDetails) {
        
        let flightFareRulesData = flightFareRuleDetails.response;
        let fairRuleSeatOriginDestination = '';
        let fairRules = [];
        let tripJackFlight = false;
        let kafilaFlight=false
       
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return') && (this.props.location.state && this.props.location.state.data && this.props.location.state.data.tbo_response && this.props.location.state.data.tbo_response.flight_itinerary && this.props.location.state.data.tbo_response.flight_itinerary.booking_from === 'tripjack'))
        {
            tripJackFlight = true;
        }
        else if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return')&& (this.props.location.state && this.props.location.state.data && this.props.location.state.data.tbo_response && this.props.location.state.data.tbo_response.flight_itinerary && this.props.location.state.data.tbo_response.flight_itinerary.booking_from === 'tripjack')){
            tripJackFlight = true;
        }
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return') && (this.props.location.state && this.props.location.state.data && this.props.location.state.data.tbo_response && this.props.location.state.data.tbo_response.flight_itinerary && this.props.location.state.data.tbo_response.flight_itinerary.booking_from === 'kafila'))
        {
            kafilaFlight = true;
        }
        else if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return')&& (this.props.location.state && this.props.location.state.data && this.props.location.state.data.tbo_response && this.props.location.state.data.tbo_response.flight_itinerary && this.props.location.state.data.tbo_response.flight_itinerary.booking_from === 'kafila')){
            kafilaFlight = true;
        }

      

        if(tripJackFlight && flightFareRulesData && Object.keys(flightFareRulesData) && Object.keys(flightFareRulesData).length && Object.keys(flightFareRulesData)[0] !== '0')
        {
            fairRuleSeatOriginDestination = (flightFareRulesData && flightFareRulesData['fareRule']) ?  Object.keys(flightFareRulesData['fareRule'])  :'';
            flightFareRulesData && flightFareRulesData['fareRule'] && flightFareRulesData['fareRule'][fairRuleSeatOriginDestination] && flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr'] && Object.keys(flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr']).length > 0 &&
            flightFareRulesData && flightFareRulesData['fareRule'] && Object.keys(flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr']).forEach((fareRule, index) => {
                if (fareRule !== "SEAT_CHARGEABLE") {
                    Object.keys(flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr'][fareRule]).length > 0 &&
                        Object.keys(flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr'][fareRule]).forEach((item, key) => {
                            let rulesArrKey = ""
                            if (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].st !== undefined || flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et !== undefined) {
                                rulesArrKey = (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].st !== '' ?
                                    flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].st + " hr - " : '') +
                                    (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et !== '' ?
                                        (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et !== '8760' ?
                                            flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et + ' hr' :
                                            (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et / 24) + ' days') : '');
                            } else {
                                rulesArrKey = flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].pp
                            }
                            if (!Array.isArray(fairRules[rulesArrKey])) {
                                fairRules[rulesArrKey] = [];
                            }
                            if (!Array.isArray(fairRules[rulesArrKey][fareRule])) {
                                fairRules[rulesArrKey][fareRule] = [];
                            }
                            fairRules[rulesArrKey][fareRule] = flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item];
                        })
                }
            })
        }
        
		return (
            <PerfectScrollbar>
                <div className="inner-fare-block">
                    {
                        
                        tripJackFlight === true && flightFareRulesData && flightFareRulesData['fareRule'] && 
                        Object.keys(flightFareRulesData) && flightFareRulesData && Object.keys(flightFareRulesData).length && flightFareRulesData && Object.keys(flightFareRulesData)[0] !== '0' ? 
                                        
                        Object.keys(fairRules).length > 0 ? <React.Fragment>
                           <p>{fairRuleSeatOriginDestination}</p>
                           <div className="table-outer">
                                <div className="table-container">
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <thead>
                                            <tr>
                                                <th width="15%">Time Frame</th>
                                                <th style={{minWidth:'100px'}}>Cancellation Fee</th>
                                                <th>Date Changes Fee</th>
                                                <th>No Show</th>
                                                {/* <th width="16%">Seat Chargeable</th> */}
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                                                                                                                                                        
                                            Object.keys(fairRules).length > 0 && Object.keys(fairRules).map((fareRule, index)=>{
                                            
                                            let fairRuleCancellationFee = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['policyInfo'] ? fairRules[fareRule]['CANCELLATION']['policyInfo'].replace(/__nls__/g, '<br>') : '';
                                            let fairRuleNoShow = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['policyInfo'] ? fairRules[fareRule]['NO_SHOW']['policyInfo'].replace(/__nls__/g, '<br>') : '';
                                            let fairRuleDateChange = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['policyInfo'] ? fairRules[fareRule]['DATECHANGE']['policyInfo'].replace(/__nls__/g, '<br>') : '';
                                            // let fairRuleSeatChargeable = fairRules[fareRule]['SEAT_CHARGEABLE'] && fairRules[fareRule]['SEAT_CHARGEABLE']['policyInfo'] ? fairRules[fareRule]['SEAT_CHARGEABLE']['policyInfo'].replace(/__nls__/g, '<br>') : '';
                                            let fairRuleCancellationFeeAmount = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['amount'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['CANCELLATION']['amount'] : '';
                                            let fairRuleCancellationFeeAdFee = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['additionalFee'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['CANCELLATION']['additionalFee'] : '';
                                            let fairRuleDateChangeAmount = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['amount'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['DATECHANGE']['amount'] : '';
                                            let fairRuleDateChangeAdFee = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['additionalFee'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['DATECHANGE']['additionalFee'] : '';
                                            // let fairRuleNoShowDateSt = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['st'] ? fairRules[fareRule]['NO_SHOW']['st'].replaceAll('__nls__', '<br>') + ' hr' + ' - ' : '';
                                            // let fairRuleNoShowDateEt = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['et'] ? (fairRules[fareRule]['NO_SHOW']['et'].replaceAll('__nls__', '<br>')/24) + ' days': '';
                                            
                                            fairRuleCancellationFee  = (fairRuleCancellationFee === 'Not Applicable') || (fairRuleCancellationFee === 'CANCELLATION Not Available') || (fairRuleCancellationFee === "Not Refundable") ? fairRuleCancellationFee : fairRuleCancellationFeeAmount+fairRuleCancellationFeeAdFee && "  "+fairRuleCancellationFeeAmount +" "+  fairRuleCancellationFee;
                                            fairRuleDateChange = fairRuleDateChange === 'Not Applicable' || (fairRuleDateChange === "Not Refundable") ? fairRuleDateChange :  fairRuleDateChangeAmount+fairRuleDateChangeAdFee && "  "+fairRuleDateChangeAmount +" "+ fairRuleDateChange;  
                                            
                                            return (<tr key= {index}>
                                                <td><div dangerouslySetInnerHTML={{__html: fareRule.replaceAll("_"," ")}}/></td>
                                                <td><div dangerouslySetInnerHTML={{ __html: fairRuleCancellationFee}}/> </td>
                                                <td><div  dangerouslySetInnerHTML={{ __html: fairRuleDateChange}}/> </td>
                                                <td><div  dangerouslySetInnerHTML={{ __html: fairRuleNoShow }}/></td>
                                                {/* <td><div  dangerouslySetInnerHTML={{ __html: fairRuleSeatChargeable }}/></td> */}
                                            </tr>)
                                                })
                                                }
                                        </tbody>
                                    </table>
                                    <br />
                                        <p>The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                                        <p>Mentioned fees are Per Pax Per Sector<br /></p>
                                        <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.<br /></p>
                                        <p>For more information Please contact our helpdesk.<br /></p>
                                </div>
                            </div>
                          
                        </React.Fragment> : ( tripJackFlight === true && Object.keys(fairRules).length  === 0) ?<p>Fare Rules not available.</p> : '' : this.state.fareRuleSource === 'tripjack' && flightFareRulesData && 
                                            Object.keys(flightFareRulesData) && flightFareRulesData && Object.keys(flightFareRulesData).length && flightFareRulesData && Object.keys(flightFareRulesData)[0] === '0' ? 
                                            flightFareRulesData.map(function (data, index) {
                                                let fareRulesDetails = data.fare_rule_detail.replace(/\r\n\t/g, '');
                                                return (
                                                    <React.Fragment key={index}>
                                                        <h3 className='clear_both'>{data.origin} - {data.destination}</h3>
                                                        <div className='content-block-cms'><div dangerouslySetInnerHTML={{ __html: fareRulesDetails }} /></div>
                                                    </React.Fragment>
                                                )
                                            }) : ''
                        
                    }
                    {
                       tripJackFlight === false && kafilaFlight===false && flightFareRulesData && flightFareRulesData.map((item,index) => {
                            return (
                                <React.Fragment key={index}>
                                    <p>{item.airline} {(this.props.location.state && this.props.location.state.data && ((this.props.location.state.data.api_source === "gofly" || this.props.location.state.data.api_source === "satkar" ||this.props.location.state.data.api_source === "fbq"|| this.props.location.state.data.api_source === "riya_travel") || (this.props.location.state.data[0] &&  (this.props.location.state.data[0].api_source === "gofly" || this.props.location.state.data[0].api_source === "satkar" ||this.props.location.state.data[0].api_source === "fbq"|| this.props.location.state.data[0].api_source === "riya_travel")))) ? '':':'} {item.origin}-{item.destination}<br/></p>
                                    <div dangerouslySetInnerHTML={{ __html: item.fare_rule_detail }} />
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </PerfectScrollbar>
            );
	}

    render() {
        let accountResponse = this.props.accountResponse.myAccountResponse.response
        let totalAccountBalance = 0;
        if(accountResponse){
            if(parseInt(accountResponse.cash_balance) > 0){
                totalAccountBalance = parseInt(accountResponse.cash_balance) + parseInt(accountResponse.credit_limit);
            }else{
                totalAccountBalance = parseInt(accountResponse.credit_limit);
            }
        }
        let offeredFare = this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.fare.offered_fare;
        let showInsuffient = false
        if(totalAccountBalance < offeredFare){
            showInsuffient = true;
        }
        let kafilaCheckBooking = false;
        let riyaCheckBooking = false;
        let emptySsr = true;
        let fareQuoteData = "";
        let conditions = "";
        let data = this.props.location.state.data;
        if(this.props.location.state.fareQuote && this.props.location.state.fareQuote.results){
            fareQuoteData = this.props.location.state.fareQuote.results;
        }
        if(this.props.location.state.fareQuote && this.props.location.state.fareQuote.conditions){
            conditions = this.props.location.state.fareQuote.conditions;
        }

        let bookingData = '';
        let riyaBookingData = {}
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return') && this.props.location.state.searchData.api_source ==="kafila"){
            kafilaCheckBooking = true;
        }
        if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return') && (this.props.location.state.searchData[0].api_source ==="kafila" || this.props.location.state.searchData[1].api_source ==="kafila")){
            kafilaCheckBooking = true;
        }
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return') && this.props.location.state.searchData.api_source ==="riya_travel"){
            riyaCheckBooking = true;
        }
        if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return') && (this.props.location.state.searchData[0].api_source ==="riya_travel" || this.props.location.state.searchData[1].api_source ==="riya_travel")){
            riyaCheckBooking = true;
        }
        let fare = getFare(this.props.location.state.fareQuote);
        let result_index = '';
        let admin_unique_id = "";
        let agent_unique_id = "";
        let originCity = "";
        let destinationCity = "";
        if (this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'multi-city' || this.props.location.state.searchType === 'special-return') {
            result_index = fareQuoteData && fareQuoteData.result_index;
            admin_unique_id = (data && data.tbo_response && data.tbo_response.flight_itinerary && data.tbo_response.flight_itinerary.fare && data.tbo_response.flight_itinerary.fare.admin_unique_id )? data.tbo_response.flight_itinerary.fare.admin_unique_id : (fareQuoteData && fareQuoteData.fare && fareQuoteData.fare.admin_unique_id) ? fareQuoteData.fare.admin_unique_id : "";
            agent_unique_id = (data && data.tbo_response && data.tbo_response.flight_itinerary && data.tbo_response.flight_itinerary.fare && data.tbo_response.flight_itinerary.fare.agent_unique_id )? data.tbo_response.flight_itinerary.fare.agent_unique_id : (fareQuoteData && fareQuoteData.fare && fareQuoteData.fare.agent_unique_id) ? fareQuoteData.fare.agent_unique_id : "";
            originCity = this.props.searchQuery.originAirportCode;
            destinationCity = this.props.searchQuery.destinationAirportCode;
        } else if (this.props.location.state.searchType === 'round-trip') {
            let index = this.props.location.state.index;
            result_index = data && data[index] && data[index].result_index;
            admin_unique_id = (data && data[index] && data[index].tbo_response && data[index].tbo_response.flight_itinerary && data[index].tbo_response.flight_itinerary.fare && data[index].tbo_response.flight_itinerary.fare.admin_unique_id)? data[index].tbo_response.flight_itinerary.fare.admin_unique_id : (fareQuoteData && fareQuoteData[index] && fareQuoteData[index].fare && fareQuoteData[index].fare.admin_unique_id) ? fareQuoteData[index].fare.admin_unique_id : "";
            agent_unique_id = (data && data[index] && data[index].tbo_response && data[index].tbo_response.flight_itinerary && data[index].tbo_response.flight_itinerary.fare && data[index].tbo_response.flight_itinerary.fare.agent_unique_id)? data[index].tbo_response.flight_itinerary.fare.agent_unique_id : (fareQuoteData && fareQuoteData[index] && fareQuoteData[index].fare && fareQuoteData[index].fare.agent_unique_id) ? fareQuoteData[index].fare.agent_unique_id : "";
            originCity = this.props.searchQuery.originAirportCode;
            destinationCity = this.props.searchQuery.destinationAirportCode;
        } else if (this.props.location.state.searchType === 'round-trip-complete') {
            let index = this.props.location.state.index;
            result_index = data && data[index] && data[index].result_index;
            admin_unique_id = (data && data[index] && data[index].tbo_response && data[index].tbo_response.flight_itinerary && data[index].tbo_response.flight_itinerary.fare && data[index].tbo_response.flight_itinerary.fare.admin_unique_id) ? data[index].tbo_response.flight_itinerary.fare.admin_unique_id : (fareQuoteData && fareQuoteData[index] && fareQuoteData[index].fare && fareQuoteData[index].fare.admin_unique_id) ? fareQuoteData[index].fare.admin_unique_id : "";
            agent_unique_id = (data && data[index] && data[index].tbo_response && data[index].tbo_response.flight_itinerary && data[index].tbo_response.flight_itinerary.fare && data[index].tbo_response.flight_itinerary.fare.agent_unique_id) ? data[index].tbo_response.flight_itinerary.fare.agent_unique_id : (fareQuoteData && fareQuoteData[index] && fareQuoteData[index].fare && fareQuoteData[index].fare.agent_unique_id) ? fareQuoteData[index].fare.agent_unique_id : "";
            originCity = this.props.searchQuery.destinationAirportCode;
            destinationCity = this.props.searchQuery.originAirportCode;
        } else if (this.props.location.state.searchType === 'advance-search-round') {
            let index = this.props.location.state.index;
            result_index = this.props.location.state && this.props.location.state.priceRBD && this.props.location.state.priceRBD.results[0][0] && this.props.location.state.priceRBD.results[0][0].result_index;
            admin_unique_id = (data && data[index] && data[index].tbo_response && data[index].tbo_response.flight_itinerary && data[index].tbo_response.flight_itinerary.fare && data[index].tbo_response.flight_itinerary.fare.admin_unique_id) ? data[index].tbo_response.flight_itinerary.fare.admin_unique_id : (fareQuoteData && fareQuoteData[index] && fareQuoteData[index].fare && fareQuoteData[index].fare.admin_unique_id) ? fareQuoteData[index].fare.admin_unique_id : "";
            agent_unique_id = (data && data[index] && data[index].tbo_response && data[index].tbo_response.flight_itinerary && data[index].tbo_response.flight_itinerary.fare && data[index].tbo_response.flight_itinerary.fare.agent_unique_id) ? data[index].tbo_response.flight_itinerary.fare.agent_unique_id : (fareQuoteData && fareQuoteData[index] && fareQuoteData[index].fare && fareQuoteData[index].fare.agent_unique_id) ? fareQuoteData[index].fare.agent_unique_id : "";
            originCity = this.props.searchQuery.originAirportCode;
            destinationCity = this.props.searchQuery.destinationAirportCode;
        }
     
        if (this.props.location.state.bookingType === 'hold') {
           
			bookingData = {
                "trace_id": (data.trace_id && data.trace_id !== 'null') ? data.trace_id : this.props.location.state.trace_id,
                "pnr": data.pnr,
                "booking_id": parseInt(data.booking_id),
                "offered_fare": Number(data.tbo_response.flight_itinerary.fare.offered_fare),
                "agent_discount": Number(data.tbo_response.flight_itinerary.fare.agent_discount),
                "agent_discount_type": data.tbo_response.flight_itinerary.fare.agent_discount_type,
                "service_charge": Number(data.tbo_response.flight_itinerary.fare.service_charge),
                "cgst": Number(data.tbo_response.flight_itinerary.fare.cgst),
                "igst": Number(data.tbo_response.flight_itinerary.fare.igst),
                "sgst": Number(data.tbo_response.flight_itinerary.fare.sgst),
                "passengers": data.tbo_response.flight_itinerary.passenger,
                "segments" : flightHelper.getSegments(this.props.fareQuote.response),
                "category_name": this.props.searchQuery && this.props.searchQuery.is_domestic === 'true' ? 'Domestic' : 'International'
            }
            if(data.tbo_response.flight_itinerary.booking_from === "tripjack")
            {
                bookingData.uid = fareQuoteData.uid ? fareQuoteData.uid  : "";
                bookingData.result_index = result_index;
                bookingData.flight_cabin_class = parseInt(this.props.searchQuery.segments[0].flight_cabin_class);
                bookingData.trip_type = parseInt(this.props.searchQuery.journey_type);
                bookingData.admin_unique_id = admin_unique_id;
                bookingData.agent_unique_id = agent_unique_id;
                bookingData.offered_fare_old = fareQuoteData.fare.offered_fare_old ? parseInt(fareQuoteData.fare.offered_fare_old) : "";
                bookingData.published_fare_old = fareQuoteData.fare.published_fare_old ? fareQuoteData.fare.published_fare_old : "";
                bookingData.fare = fare;
                bookingData.tds = fareQuoteData.fare.tds ? fareQuoteData.fare.tds : "";
                bookingData.source = fareQuoteData.source ? fareQuoteData.source : "";
                bookingData.drop_booking = false;
                bookingData.price_modified = fareQuoteData.fare.price_modified ? fareQuoteData.fare.price_modified : "";
                bookingData.origin = originCity;
                bookingData.destination = destinationCity;
                bookingData.penalty = this.props.location.state.fareQuote.penalty && this.props.location.state.fareQuote.penalty;
                bookingData.booking_type = this.props.type;
                bookingData.tripjack_booking_id = data.tripjack_booking_id ? data.tripjack_booking_id : '';
                bookingData.tripjack_bookingId = data.tripjack_booking_id ? data.tripjack_booking_id : '';
                bookingData.offer_fare_old = data.tbo_response.flight_itinerary.fare.offered_fare_old ? parseInt(data.tbo_response.flight_itinerary.fare.offered_fare_old) : "";
                bookingData.conditions = conditions;
            }
            if(data.tbo_response.flight_itinerary.booking_from === "riya"){

            bookingData = {
                "trace_id": (data.trace_id && data.trace_id !== 'null') ? data.trace_id : this.props.location.state.trace_id,
                "pnr": data.pnr,
                "booking_id": parseInt(data.booking_id),
                "offered_fare": Number(data.tbo_response.flight_itinerary.fare.offered_fare),
                "agent_discount": Number(data.tbo_response.flight_itinerary.fare.agent_discount),
                "agent_discount_type": data.tbo_response.flight_itinerary.fare.agent_discount_type,
                "service_charge": Number(data.tbo_response.flight_itinerary.fare.service_charge),
                "cgst": Number(data.tbo_response.flight_itinerary.fare.cgst),
                "igst": Number(data.tbo_response.flight_itinerary.fare.igst),
                "sgst": Number(data.tbo_response.flight_itinerary.fare.sgst),
                "passengers": data.tbo_response.flight_itinerary.passenger,
                "segments" : flightHelper.getSegments(this.props.fareQuote.response),
                "category_name": this.props.searchQuery && this.props.searchQuery.is_domestic === 'true' ? 'Domestic' : 'International',
                "riya_booking_id" : data && data.tbo_response && data.tbo_response.flight_itinerary && data.tbo_response.flight_itinerary.riya_booking_id,
               "booking_id" : data.booking_id,
               "price_modified" : this.props.location.state.fareQuote && this.props.location.state.fareQuote.results && this.props.location.state.fareQuote.results.fare && this.props.location.state.fareQuote.results.fare.price_modified,
               "pnr" : data.tbo_response.flight_itinerary.pnr,
              "riya_pnr" : data.tbo_response.flight_itinerary.riya_pnr,
              "is_domestic": this.props.searchQuery.is_domestic,
              "journey_type": this.props.searchQuery.journey_type,
            }
            }
            else 
            {
                bookingData.tbo_booking_id = parseInt(data.tbo_booking_id);
                if (data.tbo_response.flight_itinerary.booking_from === "tbo") {
                    
                    // bookingData.airline_remark = this.props.fareQuote.response.results.airline_remark;
                   bookingData.airline_remark = this.props.location && this.props.location.state && this.props.location.state.searchData && this.props.location.state.searchData.airline_remark;
                }
               
            }
        }
        let leadPax = null;
        if (data) {
            for (let i = 0; i < data.tbo_response.flight_itinerary.passenger.length; i++) {
                if (data.tbo_response.flight_itinerary.passenger[i].is_lead_pax === true) {
                    leadPax = data.tbo_response.flight_itinerary.passenger[i];
                }
            }
        }
        let dataPrintTicket = {
            details: {
                "transaction_no": data.tbo_booking_id,
                "pnr": data.pnr,
                "booking_type": 'online',
                "booking_id": data.booking_id
            }
        }
        const successCb = (response) => {
            this.props.myAccountAction();
            this.props.isBookingPending(false)
            if (this.props.location.state.data.booking_status === 'ticket_pending') {
                this.props.history.push({
                    pathname: "/booking-confirm",
                    state: {
                        data: response,
                        bookingType: 'ticket_pending'
                    }
                });
            }
            else {
                this.props.history.push({
                    pathname: "/booking-confirm",
                    state: {
                        data: response,
                        bookingType: 'confirm'
                    }
                });
            }
        }

        const successCb1 = (response) => {
            const errorCb1 = (response) => {
                this.props.history.push({
                    pathname: "/booking-error",
                    state: {
                        page: 'booking',
                        response: response.message,
                        searchQuery: this.props.searchQuery,
                        productResponse: this.props.productResponse
                    }
                });
            }
            const makeTripJackBookDetailsCall = (apiCallCount) => {
                if (apiCallCount < 4 && response.booking_status === "ticket_pending") {
                  this.props.isBookingPending(true);
                  setTimeout(() => {
                    this.props.isBookingPending(false);
                    this.props.tripJackBookDetails(
                        bookingData,
                      makeTripJackBookDetailsCall.bind(null, apiCallCount + 1),
                      errorCb1
                    );
                  }, 5000);
                } else {
                  this.props.isBookingPending(false);
                  successCb(response);
                }
              };
            
              makeTripJackBookDetailsCall(0);
        }

        const errorCb = (response) => {
            this.props.history.push({
                pathname: "/booking-error",
                state: {
                    page: 'booking',
                    response: response.message,
                    searchQuery: this.props.searchQuery,
                    productResponse: this.props.productResponse
                }
            });
        }

        let loading = false;
        let fareQuote = data.tbo_response.flight_itinerary;
        let currentDate = moment(moment(getCurrentDate())).format('YYYY-MM-DD') + 'T00:00:00';

        //Show trip combined fare
        // let totalPubFare = null;
        // if (this.props.location.state.searchType === 'round-trip-complete') {
        //     totalPubFare = Number(this.props.outboundPubFare) + Number(data.tbo_response.flight_itinerary.fare.published_fare);
        // } else if (this.props.location.state.searchType === 'round-trip') {
        //     totalPubFare = Number(this.props.outboundPubFare) + Number(this.props.location.state.searchData[1].fare.published_fare)
        // }

        let stoppage_detail = data && flightHelper.getFlightStops(data.tbo_response.flight_itinerary.segments);


        let fairRuleSeatOriginDestination = '';
        let fairRuleOriginDestination = '';
        let fairRules = [];
        let riyaFlight = false;
        if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return') && (this.props.location.state && this.props.location.state.data && this.props.location.state.data.tbo_response && this.props.location.state.data.tbo_response.flight_itinerary && this.props.location.state.data.tbo_response.flight_itinerary.booking_from === 'riya'))
            {
                riyaFlight = true;
            }
            else if((this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete' || this.props.location.state.searchType === 'lcc-special-return')&& (this.props.location.state && this.props.location.state.data && this.props.location.state.data.tbo_response && this.props.location.state.data.tbo_response.flight_itinerary && this.props.location.state.data.tbo_response.flight_itinerary.booking_from === 'riya')){
                riyaFlight = true;
            }
        let flightFareRulesData = data.tbo_response.flight_itinerary.farerules ? data.tbo_response.flight_itinerary.farerules : []
        if(data.tbo_response.flight_itinerary.booking_from === 'tripjack' && Object.keys(flightFareRulesData).length > 0)
        {
            fairRuleSeatOriginDestination = Object.keys(flightFareRulesData);
            if (this.props.location.state.bookingType === 'hold')
            {
                fairRuleOriginDestination = Object.keys(flightFareRulesData);
            }
            flightFareRulesData[fairRuleSeatOriginDestination] && flightFareRulesData[fairRuleSeatOriginDestination]['tfr'] && Object.keys(flightFareRulesData[fairRuleSeatOriginDestination]['tfr']).length > 0 &&
            Object.keys(flightFareRulesData[fairRuleSeatOriginDestination]['tfr']).forEach((fareRule, index) => {
                if (fareRule !== "SEAT_CHARGEABLE") {
                    Object.keys(flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule]).length > 0 &&
                        Object.keys(flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule]).forEach((item, key) => {
                            let rulesArrKey = ""
                            if (flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].st !== undefined || flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].et !== undefined) {
                                rulesArrKey = (flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].st !== '' ?
                                    flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].st + " hr - " : '') +
                                    (flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].et !== '' ?
                                        (flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].et !== '8760' ?
                                            flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].et + ' hr' :
                                            (flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].et / 24) + ' days') : '');
                            } else {
                                rulesArrKey = flightFareRulesData[fairRuleSeatOriginDestination]['tfr'][fareRule][item].pp
                            }
                            if (!Array.isArray(fairRules[rulesArrKey])) {
                                fairRules[rulesArrKey] = [];
                            }
                            if (!Array.isArray(fairRules[rulesArrKey][fareRule])) {
                                fairRules[rulesArrKey][fareRule] = [];
                            }
                            fairRules[rulesArrKey][fareRule] = flightFareRulesData[fairRuleSeatOriginDestination]["tfr"][fareRule][item];
                        })
                }
            })
        }

        function timeConversion(n) {
            var num = n;
            var hours = (num / 60);
            var rhours = Math.floor(hours);
            if (rhours.toString().length < 2)
                rhours = "0" + rhours;
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            if (rminutes.toString().length < 2)
                rminutes = "0" + rminutes;
            return `${rhours}h:${rminutes}m`;
        }
   
        return (
            <React.Fragment>
                
               
                <section className="mid-wrapper confirm-booking">
                    <div className="container">
                        <div className="review-booking-wrap">
                            {
                                (this.props.location.state.searchType === 'round-trip') &&
                                <div className="review-step">
                                    <div className="review-step-title">
                                        <div className="cols pull-left"> <span>Booking In Progress</span> </div>
                                        <div className="cols pull-right"> </div>
                                    </div>
                                    <div className="review-step-deatil review-booking">
                                        <div className="step-deatil-outer">
                                            <div className="booking-details">
                                                <div className={`cols disabled-section`}>
                                                    <div className="booking-head"> <span>Outbound Booking In Progress</span> </div>
                                                    <div className="booking-list">
                                                        <ul>
                                                            <li><span>{this.props.searchQuery.segments[0].origin} - {this.props.searchQuery.segments[0].destination}</span>,
											<span>{this.props.location.state.searchData[0].segments[0][0].airline.airline_code} -
											{this.props.location.state.searchData[0].segments[0][0].airline.flight_number + ' '}
                                                                    {this.props.location.state.searchData[0].segments[0][0].airline.fare_class}</span></li>
                                                            <li><span>{dateFormat(this.props.location.state.searchData[0].segments[0][0].origin.dep_time, 'DD MMM YYYY')}</span>,
											<span>{flightHelper.getDepartureTime(this.props.location.state.searchData[0].segments[0][0].origin.dep_time)} -
											{flightHelper.getArivalTime(this.props.location.state.searchData[0].segments[0])}</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className={`cols`}>
                                                    <div className="booking-head"> <span>Inbound Booking</span> </div>
                                                    <div className="booking-list">
                                                        <ul>
                                                            <li>
                                                                <samp className="outbound-booking">
                                                                    { /* eslint-disable-next-line */}
                                                                    <a href="/" onClick={(e) => {e.preventDefault();this.outboundBooking()}}>Click here for inbound booking</a>
                                                                </samp>
                                                                <span>{this.props.searchQuery.segments[1].origin} - {this.props.searchQuery.segments[1].destination}</span></li>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="total-adults-price">
                                                    <div className="pull-left">Total Publish </div>
                                                    <div className="pull-right">
                                                        <i className="icon icon-rupee" />
                                                        <span>{currencyFormat(Number(this.props.location.state.searchData[0].fare.published_fare) + Number(this.props.location.state.searchData[1].fare.published_fare))}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="left-side">
                                <FlightFareSummaryComponent fareQuote={fareQuote} loading={loading} booking={true} {...this.state} />
                            </div>
                            <div className="right-side">
                                <div className="review-steps-wrap">
                                    <div className="tabs-content-wrap">
                                        {
                                            // this.props.location.state.bookingType === 'drop'
                                            //     ?
                                            //     <DropBookingComponent data={this.props.location.state.data}/>
                                            //     :
                                                <div className="step-content">
                                                    <h3 className="step-title"><span>Booking Confirmation</span> </h3>
                                                    <div className="review-filght-step">
                                                        <div
                                                            className={(this.props.location.state.bookingType === 'hold' || this.props.location.state.bookingType === 'drop' || this.props.location.state.bookingType === 'kafila-pending' ||this.props.location.state.bookingType === 'ticket_pending')
                                                                ? "review-step hold-booking-confirm"
                                                                : 'review-step'}>
                                                            <div className={(this.props.location.state.bookingType === 'confirm' ||  this.props.location.state.bookingType === 'kafila-confirm') ? "review-step-deatil select-booking" : 'review-step-deatil'}>
                                                                <div className="step-deatil-outer">
                                                                    <div className="flight-details-block">
                                                                        <div className="flight-col">
                                                                            <div className="flight-head">
                                                                                <div className="cols">
                                                                                    {
                                                                                        this.props.location.state.bookingType === 'confirm' &&
                                                                                        <span className="confirm-icon"><i className="icon icon-checked"></i></span>
                                                                                    }

                                                                                    {
                                                                                        this.props.location.state.bookingType === 'kafila-confirm' &&
                                                                                        <span className="confirm-icon"><i className="icon icon-checked"></i></span>
                                                                                    }
                                                                                    <span>{this.props.accountResponse &&
                                                                                    this.props.accountResponse.myAccountResponse.response && 
                                                                                    this.props.accountResponse.myAccountResponse.response.company_name }</span></div>
                                                                                    <div className="cols">
                                                                                    {
                                                                                        data && data.tbo_response.flight_itinerary.pnr &&
                                                                                        <span className="pnr-no">PNR : {data && data.tbo_response.flight_itinerary.pnr}</span>
                                                                                    }
                                                                                    </div>
                                                                            </div>
                                                                            <div className="flight-list">
                                                                                <div className="cols">
                                                                                    <ul>
                                                                                        <li>{leadPax && leadPax.address_line_1}
                                                                                            {leadPax && leadPax.address_line_2 !== '' ? ', ' + (leadPax.address_line_2 ? leadPax.address_line_2 : '') : ''}</li>
                                                                                        <li>{leadPax && leadPax.city}</li>
                                                                                        <li>Phone: {leadPax && leadPax.contact_no}</li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div className="cols">
                                                                                    <ul>
                                                                                        <li>{this.props.location.state.bookingType === 'hold' && <span className="text-bold">Hold</span>}
                                                                                            {this.props.location.state.bookingType === 'ticket_pending' && <span className="text-bold">Pending</span>}
                                                                                            {this.props.location.state.bookingType === 'confirm' && <span className="text-bold">Confirmed</span>}
																							{this.props.location.state.bookingType === 'drop' && <span className="text-bold">Pending</span>}

                                                                                            {this.props.location.state.bookingType === 'kafila-confirm' && <span className="text-bold">Confirmed</span>}
                                                                                            {this.props.location.state.bookingType === 'kafila-pending' && <span className="text-bold">Pending</span>}
                                                                                        </li>
                                                                                        <li>Booked on: <span className="text-bold">{data && dateFormat(currentDate, 'DD MMM YYYY')}</span></li>
                                                                                        <li>Travel Date: <span className="text-bold">{data && dateFormat(data.tbo_response.flight_itinerary.segments[0].origin.dep_time, 'DD MMM YYYY')}</span> </li>
                                                                                        <li>CABIN: <span className="text-bold">{data && data.tbo_response && data.tbo_response.flight_itinerary && data.tbo_response.flight_itinerary.segments[0].cabin_class_name}</span></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-step">
                                                            <div className="review-step-title">
                                                                <div className="cols pull-left"> <span>FLIGHT INFORMATION</span> </div>
                                                                <div className="cols pull-right"></div>
                                                            </div>
                                                            <div className="review-step-deatil">
                                                                <div className="step-deatil-outer">
                                                                    <div className="flight-information">
                                                                        <div className="inner-fl-info">
                                                                            <table className="fl-book-info">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Flight No</th>
                                                                                        <th>Origin</th>
                                                                                        <th>Destination</th>
                                                                                        <th>Dep Date Time</th>
                                                                                        <th>Arr Date Time</th>
                                                                                        <th>Class</th>
                                                                                        <th>Status</th>
                                                                                        <th>Check-in</th>
                                                                                        <th>Baggage</th>
                                                                                        <th>Duration</th>
                                                                                        {
                                                                                            data && data.tbo_response && data.tbo_response.flight_itinerary &&
                                                                                            (data.tbo_response.flight_itinerary.booking_from ==="tbo" || data.tbo_response.flight_itinerary.booking_from ==="tripjack") &&
                                                                                            <th>Operated By</th>
                                                                                        }
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                       
                                                                                        stoppage_detail && stoppage_detail.map((item, index) => {
                                                                                           
                                                                                            let originTerminal = '';
                                                                                            let destinationTerminal = '';
                                                                                            
                                                                                            if(item.origin_terminal !== '' && item.origin_terminal){
                                                                                                if(data.tbo_response.flight_itinerary.booking_from === "tripjack")
                                                                                                {
                                                                                                    originTerminal = '-' + item.origin_terminal;
                                                                                                }
                                                                                                else
                                                                                                {
                                                                                                    originTerminal = '-T-' + item.origin_terminal;
                                                                                                }
                                                                                            }
                                                                                            if(item.destination_terminal !== '' && item.destination_terminal)
                                                                                            {
                                                                                                if(data.tbo_response.flight_itinerary.booking_from === "tripjack")
                                                                                                {
                                                                                                    destinationTerminal = '-' + item.destination_terminal;
                                                                                                }
                                                                                                else
                                                                                                {
                                                                                                    destinationTerminal = '-T-' + item.destination_terminal;
                                                                                                }
                                                                                            }
                                                                                            return (
                                                                                                <tr key={index}>
                                                                                                    <td>{item.airline.airline_code + '-' + item.airline.flight_number}</td>
                                                                                                    <td>{item.origin_airport_code} ({item.origin_airport_name + '' + originTerminal})</td>
                                                                                                    <td>{item.destination_airport_code} ({item.destination_airport_name + '' + destinationTerminal})</td>
                                                                                                    <td>{dateFormat(item.origin_dep_time, 'DD MM YYYY HH:MM')}</td>
                                                                                                    <td>{dateFormat(item.destination_arr_time, 'DD MM YYYY HH:MM')}</td>
                                                                                                    <td>{item.airline.fare_class}</td>
                                                                                                    <td>{item.status}</td>
                                                                                                    <td>{item.baggage ? item.baggage :'-'}</td>
                                                                                                    <td>{item.cabin_baggage ? item.cabin_baggage : '-' }</td>
                                                                                                    <td>
                                                                                                        {item.duration
                                                                                                            ? data.tbo_response.flight_itinerary.booking_from === "fareboutique"
                                                                                                                ? formatDuration(item.duration)
                                                                                                                : timeConversion(item.duration)
                                                                                                            : '-'}
                                                                                                    </td>
                                                                                                    {
                                                                                                        data && data.tbo_response && data.tbo_response.flight_itinerary && 
                                                                                                        (data.tbo_response.flight_itinerary.booking_from ==="tbo" || data.tbo_response.flight_itinerary.booking_from ==="tripjack") &&
                                                                                                        <td>{item.operating_carrier_name ? item.operating_carrier_name : item.operating_carrier ? item.operating_carrier : '-'}</td>
                                                                                                    }
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="review-step">
                                                            <div className="review-step-title">
                                                                <div className="cols pull-left"> <span>Passenger Details</span> </div>
                                                                <div className="cols pull-right">  </div>
                                                            </div>

                                                            <div className="review-step-deatil">
                                                                <div className="step-deatil-outer">
                                                                    <div className="passenger-information">
                                                                        <div className="passenger-fl-info">

                                                                            {
                                                                                data && data.tbo_response.flight_itinerary.passenger.map((item, index) => {
                                                                                    let paxType = '';
                                                                                    if (item.pax_type === 1)
                                                                                        paxType = 'Adult';
                                                                                    else if (item.pax_type === 2)
                                                                                        paxType = 'Child';
                                                                                    else
                                                                                        paxType = 'Infant';
                                                                                    let gender = (item.pax_title === 'Mr'||item.pax_title === 'Mstr') ? 'Male' : 'Female';
                                                                                    return (
                                                                                        <div className="cols" key={index}>
                                                                                            <div className="adult-title"><span>Passenger {index + 1}</span> <small>({paxType})</small></div>
                                                                                            <ul className="psg-list">
                                                                                                <li>
                                                                                                    <span>Name:</span>
                                                                                                    <small>{(item.title ? item.title :'') + ' ' + item.first_name + ' ' + item.last_name}</small>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <span>Gender:</span>
                                                                                                    <small>{gender}</small>
                                                                                                </li>
                                                                                                <li style={{ display: (item.is_lead_pax === true) ? 'block' : 'none' }}>
                                                                                                    <span>Address:</span>
                                                                                                    <small>{item.address_line_1}</small>
                                                                                                </li>
                                                                                                <li style={{ display: (item.is_lead_pax === true) ? 'block' : 'none' }}>
                                                                                                    <span>Mobile No:</span>
                                                                                                    <small>{item.contact_no}</small>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <span>DOB:</span>
                                                                                                    <small>{dateFormat(item.date_of_birth, 'DD MMM YYYY')}</small>
                                                                                                </li>
                                                                                                <li style={{ display: (item.is_lead_pax === true) ? 'block' : 'none' }}>
                                                                                                    <span>Email:</span>
                                                                                                    <small>{item.email}</small>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
														{
                                                            data && data.tbo_response.flight_itinerary.booking_type === 'drop' && data.tbo_response.flight_itinerary.booking_from !== "riya"  &&
                                                            <div className="review-step">
                                                                <div className="review-step-title">
                                                                    <div className="cols pull-left"> <span>Fare Rules</span> </div>
                                                                    <div className="cols pull-right"> </div>
                                                                </div>
                                                                <div className="review-step-deatil">
                                                                    <div className="step-deatil-outer">
                                                                        <div className="fare-rule-info">
                                                                            {
                                                                                data.tbo_response.flight_itinerary.booking_from === "tripjack" && 
                                                                                this.renderFareRule(this.props.tripJackFlightFareRuleDetails)
                                                                            }
                                                                            {
                                                                                data.tbo_response.flight_itinerary.booking_from === "tbo" && 
                                                                                this.renderFareRule(this.props.flightFareRuleDetails)
                                                                            }
                                                                            {
                                                                                data.tbo_response.flight_itinerary.booking_from === "gofly" && 
                                                                                this.renderFareRule(this.props.goflyFlightFareRuleDetails)
                                                                            }
                                                                            {
                                                                                data.tbo_response.flight_itinerary.booking_from === "efly" && 
                                                                                this.renderFareRule(this.props.eflyFlightFareRuleDetails)
                                                                            }
                                                                            {
                                                                                data.tbo_response.flight_itinerary.booking_from === "airiq" && 
                                                                                this.renderFareRule(this.props.airIQFlightFareRuleDetails)
                                                                            }
                                                                            {
                                                                                data.tbo_response.flight_itinerary.booking_from === "satkar" && 
                                                                                this.renderFareRule(this.props.satkarFlightFareRuleDetails)
                                                                            }
                                                                              {
                                                                                data.tbo_response.flight_itinerary.booking_from === "fareboutique" && 
                                                                                this.renderFareRule(this.props.fareBoutiqueFlightFareRuleDetails)
                                                                            }
                                                                            {/* {
                                                                                data.tbo_response.flight_itinerary.booking_from === "riya" && 
                                                                                this.renderFareRule(this.props.riyaFlightFareRuleDetails)
                                                                            } */}
                                                                            {
                                                                                data.tbo_response.flight_itinerary.booking_from === "kafila" &&
                                                                                <PerfectScrollbar>
                                                                                    <div className="inner-fare-block">
                                                                                    <p>These Fare Rules are just a guideline for your convenience.</p><br />
                                                                                        <p>Cancellation/Change fee and terms and conditions are subject to change without prior notice.<br />
                                                                                            To know exact charges kindly visit the respective airline website or contact our support center.</p>
                                                                                        {
                                                                                       
                                                                                        // <React.Fragment>
                                                                                        //                 <h5>{this.props.searchQuery && this.props.searchQuery.originAirportCode}-{this.props.searchQuery && this.props.searchQuery.destinationAirportCode} </h5>
                                                                                        //                 <div className="table-outer">
                                                                                        //                      <div className="table-container">
                                                                                        //                          <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                                        //                              <thead>
                                                                                        //                                  <tr>
                                                                                        //                                      <th width="15%">Time Frame</th>
                                                                                        //                                      <th>Cancellation Fee</th>
                                                                                        //                                      <th>Date Changes Fee</th>
                                                                                        //                                     { /*<th>No Show</th>*/}
                                                                                        //                                      {/* <th width="16%">Seat Chargeable</th> */}
                                                                                                                             
                                                                                        //                                  </tr>
                                                                                        //                              </thead>
                                                                                        //                              <tbody>
                                                                                        //                                  {
                                                                                        //                                      this.props.fareQuote && this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.fare_rules.length > 0 && this.props.fareQuote.response.results.fare_rules.map((item)=>{
                                                                                        //                                             return  (<tr key= {""}>
                                                                                        //                                             <td><div dangerouslySetInnerHTML={{__html: item.time_frame}}/></td>
                                                                                        //                                             <td><div dangerouslySetInnerHTML={{ __html: item.cancellation_fee}}/> </td>
                                                                                        //                                             <td><div  dangerouslySetInnerHTML={{ __html: item.date_change_fee}}/> </td>
                                                                                        //                                             {/*<td><div  dangerouslySetInnerHTML={{ __html: "fairRuleNoShow" }}/></td>*/}
                                                                                        //                                             {/* <td><div  dangerouslySetInnerHTML={{ __html: fairRuleSeatChargeable }}/></td> */}
                                                                                        //                                         </tr>)
                                                                                        //                                     })                                                                                                          
                                                                                        //                                   }
                                                                                        //                              </tbody>
                                                                                        //                          </table>
                                                                                        //                          <br />
                                                                                        //                              <p>The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                                                                                        //                              <p>Mentioned Charges are Per Pax Per Sector<br /></p>
                                                                                        //                              <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.<br /></p>
                                                                                        //                              <p>For more information Please contact our helpdesk.<br /></p>
                                                                                        //                      </div>
                                                                                        //                  </div>
                                                                                        //              </React.Fragment>
                                                                                    }
                                                                                    </div>
                                                                                </PerfectScrollbar>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            data && data.tbo_response.flight_itinerary.farerules  &&
                                                            <div className="review-step">
                                                                <div className="review-step-title">
                                                                    <div className="cols pull-left"> <span>Fare Rules</span> </div>
                                                                    <div className="cols pull-right"> </div>
                                                                </div>
                                                                <div className="review-step-deatil">
                                                                    <div className="step-deatil-outer">
                                                                        <div className="fare-rule-info">
                                                                            {
                                                                                data && data.tbo_response && data.tbo_response.flight_itinerary && data.tbo_response.flight_itinerary.farerules && Object.keys(data && data.tbo_response.flight_itinerary.farerules).length === 0 &&
                                                                                <p>
                                                                                    <br />Fare Rules not available.<br />
                                                                                    <p><br />The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                                                                                    <p>Mentioned Charges are Per Pax Per Sector<br /></p>
                                                                                    <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.<br /></p>
                                                                                    <p>For more information Please contact our helpdesk.<br /></p>
                                                                                </p>
                                                                            }
                                                                            {
                                                                                 data && data.tbo_response && data.tbo_response.flight_itinerary && data.tbo_response.flight_itinerary.farerules && Object.keys(data.tbo_response.flight_itinerary.farerules).length !== 0 &&
                                                                                <PerfectScrollbar>
                                                                                    <div className="inner-fare-block">
                                                                                    {
                                                                                        
                                                                                        data.tbo_response.flight_itinerary.booking_from !== "kafila" && data.tbo_response.flight_itinerary.booking_from !== "gofly" && data.tbo_response.flight_itinerary.booking_from !== "airiq" &&  data.tbo_response.flight_itinerary.booking_from !== "tripjack" &&  data.tbo_response.flight_itinerary.booking_from !== "satkar" && data.tbo_response.flight_itinerary.booking_from !== "fareboutique" && data.tbo_response.flight_itinerary.booking_from !== "riya" && data.tbo_response.flight_itinerary.farerules.map((item, index) => {
                                                                                            let holdFareType = '';
                                                                                            if (this.props.location.state.bookingType === 'hold')
                                                                                                holdFareType = <React.Fragment>{item.airline}:{item.origin}-{item.destination}<br /></React.Fragment>;
                                                                                            else
                                                                                                holdFareType = '';

                                                                                            return (
                                                                                                <React.Fragment key={index}>
                                                                                                    <p>{holdFareType}</p>
                                                                                                    <div dangerouslySetInnerHTML={{ __html: item.farerule_detail }} />
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                        (data.tbo_response.flight_itinerary.booking_from === "gofly" || data.tbo_response.flight_itinerary.booking_from === "airiq") && !Array.isArray(data && data.tbo_response.flight_itinerary.farerules) &&
                                                                                        <div className="step-deatil-outer">
                                                                                            <div dangerouslySetInnerHTML={{ __html: data && data.tbo_response.flight_itinerary.farerules }} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (data.tbo_response.flight_itinerary.booking_from === "gofly" || data.tbo_response.flight_itinerary.booking_from === "airiq") && Array.isArray(data && data.tbo_response.flight_itinerary.farerules) &&
                                                                                        <PerfectScrollbar>
                                                                                            <div className="inner-fare-block">
                                                                                                {

                                                                                                    data.tbo_response.flight_itinerary.booking_from === "satkar" && data.tbo_response.flight_itinerary.farerules.map((item, index) => {
                                                                                                        let holdFareType = '';
                                                                                                        holdFareType = <React.Fragment>{item.airline}{item.airline ? ':' : ''}{item.origin}-{item.destination}<br /></React.Fragment>;
                                                                                                        return (
                                                                                                            <React.Fragment key={index}>
                                                                                                                <p className='clear_both'>{holdFareType}</p>
                                                                                                                <div className='content-block-cms'>
                                                                                                                    <div dangerouslySetInnerHTML={{ __html: item.fare_rule_detail }} />
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </PerfectScrollbar>
                                                                                    }


                                                                                    {
                                                                                        
                                                                                        data.tbo_response.flight_itinerary.booking_from === "satkar" && data.tbo_response.flight_itinerary.farerules.map((item, index) => {
                                                                                      
                                                                                            let holdFareType = '';
                                                                                                holdFareType = <React.Fragment>{item.airline}{item.airline ? ':' : ''}{item.origin}-{item.destination}<br /></React.Fragment>;
                                                                                            return (
                                                                                                <React.Fragment key={index}>
                                                                                                    <p className='clear_both'>{holdFareType}</p>
                                                                                                    <div className='content-block-cms'>
                                                                                                        <div dangerouslySetInnerHTML={{ __html: item.fare_rule_detail }} />
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                     {
                                                                                        (data.tbo_response.flight_itinerary.booking_from === "gofly" || data.tbo_response.flight_itinerary.booking_from === "airiq") && Array.isArray(data && data.tbo_response.flight_itinerary.farerules) &&
                                                                                        <PerfectScrollbar>
                                                                                            <div className="inner-fare-block">
                                                                                                {

                                                                                                    data.tbo_response.flight_itinerary.booking_from === "fareboutique" && data.tbo_response.flight_itinerary.farerules.map((item, index) => {
                                                                                                        let holdFareType = '';
                                                                                                        holdFareType = <React.Fragment>{item.airline}{item.airline ? ':' : ''}{item.origin}-{item.destination}<br /></React.Fragment>;
                                                                                                        return (
                                                                                                            <React.Fragment key={index}>
                                                                                                                <p className='clear_both'>{holdFareType}</p>
                                                                                                                <div className='content-block-cms'>
                                                                                                                    <div dangerouslySetInnerHTML={{ __html: item.fare_rule_detail }} />
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </PerfectScrollbar>
                                                                                    }

                                                                                       {
                                                                                        
                                                                                        data.tbo_response.flight_itinerary.booking_from === "fareboutique" && data.tbo_response.flight_itinerary.farerules.map((item, index) => {
                                                                                      
                                                                                            let holdFareType = '';
                                                                                                holdFareType = <React.Fragment>{item.airline}{item.airline ? ':' : ''}{item.origin}-{item.destination}<br /></React.Fragment>;
                                                                                            return (
                                                                                                <React.Fragment key={index}>
                                                                                                    <p className='clear_both'>{holdFareType}</p>
                                                                                                    <div className='content-block-cms'>
                                                                                                        <div dangerouslySetInnerHTML={{ __html: item.fare_rule_detail }} />
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                        
                                                                                        data.tbo_response.flight_itinerary.booking_from === "riya" && data.tbo_response.flight_itinerary.segments.map((item, index) => {
                                                                                           
                                                                                            let holdFareType = '';
                                                                                                holdFareType = <React.Fragment>{item.airline_code}{item.airline_code ? ':' : ''}{item.origin.airport.airport_code}-{item.destination.airport.airport_code}<br /></React.Fragment>;
                                                                                            return (
                                                                                                <React.Fragment key={index}>
                                                                                                    <p className='clear_both'>{holdFareType}</p>
                                                                                                    <div className='content-block-cms'>
                                                                                                        <div dangerouslySetInnerHTML={{ __html: data.tbo_response.flight_itinerary.farerules }} />
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                            {
                                                                                                data.tbo_response.flight_itinerary.booking_from === "kafila" && !Array.isArray(data && data.tbo_response.flight_itinerary.farerules) &&
                                                                                              
                                                                                                <div className="step-deatil-outer">
                                                                                                
                                                                                                    <div dangerouslySetInnerHTML={{ __html: data && data.tbo_response.flight_itinerary.farerules }} />
                                                                                                </div>

                                                                                            }  
                                                                                           
                                                                                    {
                                                                                        data.tbo_response.flight_itinerary.booking_from === "tripjack" && 
                                                                                        Object.keys(fairRules).length > 0 && <React.Fragment>
                                                                                           <p className='clear_both'>{fairRuleOriginDestination}</p>
                                                                                   
                                                                                           <div className="table-outer">
                                                                                                <div className="table-container content-block-cms">
                                                                                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th width="15%">Time Frame</th>
                                                                                                                <th style={{minWidth:'100px'}}>Cancellation Fee</th>
                                                                                                                <th>Date Changes Fee</th>
                                                                                                                <th>No Show</th>
                                                                                                                {/* <th width="16%">Seat Chargeable</th> */}
                                                                                                                
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                                                                                                                        
                                                                                                            Object.keys(fairRules).length > 0 && Object.keys(fairRules).map((fareRule, index)=>{
                                                                                                            
                                                                                                            let fairRuleCancellationFee = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['policyInfo'] ? fairRules[fareRule]['CANCELLATION']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                                                                            let fairRuleNoShow = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['policyInfo'] ? fairRules[fareRule]['NO_SHOW']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                                                                            let fairRuleDateChange = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['policyInfo'] ? fairRules[fareRule]['DATECHANGE']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                                                                            // let fairRuleSeatChargeable = fairRules[fareRule]['SEAT_CHARGEABLE'] && fairRules[fareRule]['SEAT_CHARGEABLE']['policyInfo'] ? fairRules[fareRule]['SEAT_CHARGEABLE']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                                                                            let fairRuleCancellationFeeAmount = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['amount'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['CANCELLATION']['amount'] : '';
                                                                                                            let fairRuleCancellationFeeAdFee = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['additionalFee'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['CANCELLATION']['additionalFee'] : '';
                                                                                                            let fairRuleDateChangeAmount = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['amount'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['DATECHANGE']['amount'] : '';
                                                                                                            let fairRuleDateChangeAdFee = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['additionalFee'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['DATECHANGE']['additionalFee'] : '';
                                                                                                            // let fairRuleNoShowDateSt = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['st'] ? fairRules[fareRule]['NO_SHOW']['st'].replaceAll('__nls__', '<br>') + ' hr' + ' - ' : '';
                                                                                                            // let fairRuleNoShowDateEt = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['et'] ? (fairRules[fareRule]['NO_SHOW']['et'].replaceAll('__nls__', '<br>')/24) + ' days': '';
                                                                                                            
                                                                                                            fairRuleCancellationFee  = (fairRuleCancellationFee === 'Not Applicable') || (fairRuleCancellationFee === 'CANCELLATION Not Available') || (fairRuleCancellationFee === "Not Refundable") ? fairRuleCancellationFee : fairRuleCancellationFeeAmount+fairRuleCancellationFeeAdFee && "  "+fairRuleCancellationFeeAmount +" "+  fairRuleCancellationFee;
                                                                                                            fairRuleDateChange = fairRuleDateChange === 'Not Applicable' || (fairRuleDateChange === "Not Refundable") ? fairRuleDateChange :  fairRuleDateChangeAmount+fairRuleDateChangeAdFee && "  "+fairRuleDateChangeAmount +" "+ fairRuleDateChange;  
                                                                                                            
                                                                                                            return (<tr key= {index}>
                                                                                                                <td><div dangerouslySetInnerHTML={{__html: fareRule.replaceAll("_"," ")}}/></td>
                                                                                                                <td><div dangerouslySetInnerHTML={{ __html: fairRuleCancellationFee}}/> </td>
                                                                                                                <td><div  dangerouslySetInnerHTML={{ __html: fairRuleDateChange}}/> </td>
                                                                                                                <td><div  dangerouslySetInnerHTML={{ __html: fairRuleNoShow }}/></td>
                                                                                                                {/* <td><div  dangerouslySetInnerHTML={{ __html: fairRuleSeatChargeable }}/></td> */}
                                                                                                            </tr>)
                                                                                                             })
                                                                                                             }
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                    <br />
                                                                                                                <p>The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                                                                                                                <p>Mentioned Charges are Per Pax Per Sector<br /></p>
                                                                                                                <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.<br /></p>
                                                                                                                <p>For more information Please contact our helpdesk.<br /></p>
                                                                                                </div>
                                                                                            </div>
                                                                                          
                                                                                        </React.Fragment> 
                                                                                        }
                                                                                    </div>
                                                                                </PerfectScrollbar>
                                                                            }
                                                                            {
                                                                                data && data.tbo_response && data.tbo_response.flight_itinerary && data.tbo_response.flight_itinerary.farerules && Object.keys(data.tbo_response.flight_itinerary.farerules).length !== 0 &&  data.tbo_response.flight_itinerary.booking_from === "kafila" && 
                                                                                Array.isArray(data && data.tbo_response.flight_itinerary.farerules) &&
                                                                                <PerfectScrollbar>
                                                                                    <div className="inner-fare-block">
                                                                                    {
                                                                                        data.tbo_response.flight_itinerary.farerules.length>0 ?
                                                                                        <React.Fragment>
                                                                                                        <h5 className='clear_both'>{this.props.searchQuery && this.props.searchQuery.originAirportCode}-{this.props.searchQuery && this.props.searchQuery.destinationAirportCode} </h5>
                                                                                                        <div className="table-outer">
                                                                                                             <div className="table-container content-block-cms">
                                                                                                                 <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                                                                     <thead>
                                                                                                                         <tr>
                                                                                                                             <th width="15%">Time Frame</th>
                                                                                                                             <th>Cancellation Fee</th>
                                                                                                                             <th>Date Changes Fee</th>
                                                                                                                            { /*<th>No Show</th>*/}
                                                                                                                             {/* <th width="16%">Seat Chargeable</th> */}
                                                                                                                             
                                                                                                                         </tr>
                                                                                                                     </thead>
                                                                                                                     <tbody>
                                                                                                                         {
                                                                                                                            data &&data.tbo_response && data.tbo_response.flight_itinerary  &&  data.tbo_response.flight_itinerary.farerules  && data.tbo_response.flight_itinerary.farerules.length>0&&data.tbo_response.flight_itinerary.farerules.map((item,index)=>{
                                                                                                                                    return  (<tr key= {index}>
                                                                                                                                    <td>{ item.time_frame}</td>
                                                                                                                                    <td>{ item.cancellation_fee} </td>
                                                                                                                                    <td>{ item.date_change_fee} </td>
                                                                                                                                    {/*<td><div  dangerouslySetInnerHTML={{ __html: "fairRuleNoShow" }}/></td>*/}
                                                                                                                                    {/* <td><div  dangerouslySetInnerHTML={{ __html: fairRuleSeatChargeable }}/></td> */}
                                                                                                                                </tr>)
                                                                                                                            })                                                                                                          
                                                                                                                          }
                                                                                                                     </tbody>
                                                                                                                 </table>
                                                                                                                 <br />
                                                                                                                     <p>The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                                                                                                                     <p>Mentioned Charges are Per Pax Per Sector<br /></p>
                                                                                                                     <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.<br /></p>
                                                                                                                     <p>For more information Please contact our helpdesk.<br /></p>
                                                                                                             </div>
                                                                                                         </div>
                                                                                                     </React.Fragment>:<React.Fragment>Fare Rules Are Not Available</React.Fragment>
                                                                                    }
                                                                                    </div>
                                                                                </PerfectScrollbar>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        
                                                       
                                                    {
                                                        kafilaCheckBooking === false &&
                                                        <div className="review-step">
                                                            <div className="review-step-title">
                                                                <div className="cols pull-left"> <span>SSR Details</span> </div>
                                                                <div className="cols pull-right"></div>
                                                            </div>
                                                            <div className="review-step-deatil">
                                                                <div className="step-deatil-outer">
                                                                    <div className="flight-information">
                                                                        <div className="inner-fl-info">
                                                                            <table className="fl-book-info">
                                                                                <thead>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {/* {
                                                                                        data && data.tbo_response.flight_itinerary.passenger.map((itemOuter, indexOuter) => {
                                                                                            if (itemOuter.ssr.length === 0) {
                                                                                                emptySsr = true;
                                                                                            } else {
                                                                                                emptySsr = false;
                                                                                            }
                                                                                            return itemOuter.ssr.map((item, index) => {
                                                                                                return (
                                                                                                    <tr key={index}>
                                                                                                        <td>{itemOuter.title + ' ' + itemOuter.first_name + ' ' + itemOuter.last_name}</td>
                                                                                                        <td>{item.ssr_code}</td>
                                                                                                        <td>{item.detail}</td>
                                                                                                        <td>{item.status === 1 ? 'Confirmed' : 'Pending'}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        })
                                                                                    } */}
                                                                               
                                                                                    {
                                                                                       
                                                                                        /* eslint-disable-next-line */
                                                                                        data && data.tbo_response.flight_itinerary.passenger.map((itemOuter, indexOuter) => {
                                                                              
                                                                                            if (itemOuter.baggage && itemOuter.baggage.length !== 0) {
                                                                                                return (
                                                                                                    /* eslint-disable-next-line */
                                                                                                    itemOuter.baggage.map((item, index) => {
                                                                                                    
                                                                                                        if (!item.is_default) {
                                                                                                           
                                                                                                            emptySsr = false;
                                                                                                            return (
                                                                                                                <tr key={index}>
                                                                                                                    <td>{(itemOuter.title ? itemOuter.title :'') + ' ' + itemOuter.first_name + ' ' + itemOuter.last_name}</td>
                                                                                                                    <td>{item.ssr_code}</td>
                                                                                                                    <td>{item.ssr_type}</td>
                                                                                                                    <td>{riyaFlight ? item.description : item.weight}</td>
                                                                                                                </tr>
                                                                                                            )
                                                                                                        }
                                                                                                    })
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                         /* eslint-disable-next-line */
                                                                                         data && data.tbo_response.flight_itinerary.passenger.map((itemOuter, indexOuter) => {
                                                                                         
                                                                                            if (itemOuter.meal && itemOuter.meal.length !== 0) {
                                                                                                return (
                                                                                                    /* eslint-disable-next-line */
                                                                                                    itemOuter.meal.map((item, index) => {
                                                                                                        if (!item.is_default) {
                                                                                                            emptySsr = false;
                                                                                                            return (
                                                                                                                <tr key={index}>
                                                                                                                    <td>{itemOuter.title + ' ' + itemOuter.first_name + ' ' + itemOuter.last_name}</td>
                                                                                                                    <td>{item.ssr_code}</td>
                                                                                                                    <td>{item.ssr_type}</td>
                                                                                                                    <td>{riyaFlight ?item.description:  item.meal_description}</td>
                                                                                                                </tr>
                                                                                                            )
                                                                                                        }
                                                                                                    })
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }
                                                                            
                                                                                    {
                                                                                     /* eslint-disable-next-line */
                                                                                        data && data.tbo_response.flight_itinerary.passenger.map((itemOuter, indexOuter) => {
                                                                                    
                                                                                            if (itemOuter.seat && itemOuter.seat.length !== 0) {
                                                                                                return (
                                                                                                    /* eslint-disable-next-line */
                                                                                                    itemOuter.seat.map((item, index) => {
                                                                                     
                                                                                                        if (!item.is_default) {
                                                                                                            emptySsr = false;
                                                                                                            return (
                                                                                                                <tr key={index}>
                                                                                                                    <td>{itemOuter.title + ' ' + itemOuter.first_name + ' ' + itemOuter.last_name}</td>
                                                                                                                    <td>{riyaFlight ? item.SeatName: item.ssr_code}</td>
                                                                                                                    <td>{riyaFlight ? "seat": item.ssr_type}</td>
                                                                                                                    <td>{item.meal_description}</td>
                                                                                                                </tr>
                                                                                                            )
                                                                                                        }
                                                                                                    })
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                        emptySsr &&
                                                                                        <tr>
                                                                                            <td>SSR information is not available</td>
                                                                                        </tr>
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                        <div className="btn-block text-right">
                                                            {
                                                                this.props.location.state.bookingType === 'hold' && data.tbo_response.flight_itinerary.booking_from === "tripjack" &&
                                                                <React.Fragment>
                                                                    { /* eslint-disable-next-line */}
                                                                    <a href="/" className={`btn btn-blue loading-btn`}
                                                                    style={{pointerEvents:showInsuffient ? "none" : ""}}
                                                                        onClick={(e) =>{
                                                                            e.preventDefault();
                                                                            this.handleConfirmationPopup(true, 'tripjack-hold-ticket-booking')
                                                                        }}><span>Confirm Booking </span>

                                                                    </a>
                                                                    {
                                                                        this.state.loadings===true //this.props.ticketBookingData.loading
                                                                            ?
                                                                            <div className="loader-hotel">
                                                                                <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/flight-loader.gif" alt="loader" /></div>
                                                                                <div className="overlay-loader show"></div>
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                            {
                                                                this.props.location.state.bookingType === 'hold' && data.tbo_response.flight_itinerary.booking_from !== "tripjack" && data.tbo_response.flight_itinerary.booking_from !== "riya" &&
                                                                <React.Fragment>
                                                                    { /* eslint-disable-next-line */}
                                                                    <a href="/" className={`btn btn-blue loading-btn`}
                                                                    style={{pointerEvents:showInsuffient ? "none" : ""}}
                                                                        onClick={(e) =>{
                                                                            e.preventDefault();
                                                                            this.handleConfirmationPopup(true, 'hold-ticket-booking')
                                                                        }}><span>Confirm Booking </span>

                                                                    </a>
                                                                    
                                                                    {
                                                                        (this.props.ticketBookingData && this.props.ticketBookingData.loading) || (this.props.tripJackTicketBookDetails && this.props.tripJackTicketBookDetails.loading) 
                                                                            ?
                                                                            <div className="loader-hotel">
                                                                                <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/flight-loader.gif" alt="loader" /></div>
                                                                                <div className="overlay-loader show"></div>
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                            {
                                                                (this.props.location.state.bookingType === 'confirm' || this.props.location.state.bookingType === 'kafila-confirm') &&
                                                                <React.Fragment>
                                                                    <button type="button" className="btn btn-primary" onClick={() => this.printInvoice(dataPrintTicket)}>Print Invoice</button>
                                                                    <button type="button" className="btn btn-primary" onClick={() => this.printTicket(dataPrintTicket)}>Print Ticket</button>
                                                                </React.Fragment>
                                                            }
                                                            {
                                                                this.props.location.state.bookingType === 'hold' && data.tbo_response.flight_itinerary.booking_from === "riya" &&
                                                                <React.Fragment>
                                                                    { /* eslint-disable-next-line */}
                                                                    <a href="/" className={`btn btn-blue loading-btn`}
                                                                    style={{pointerEvents:showInsuffient ? "none" : ""}}
                                                                        onClick={(e) =>{
                                                                            e.preventDefault();
                                                                            this.handleConfirmationPopup(true, 'riya-hold-ticket-booking')
                                                                        }}><span>Confirm Booking </span>

                                                                    </a>
                                                                    
                                                                    {
                                                                         (this.state.loadings)
                                                                            ?
                                                                            <div className="loader-hotel">
                                                                                <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/flight-loader.gif" alt="loader" /></div>
                                                                                <div className="overlay-loader show"></div>
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        data.tbo_response.flight_itinerary.booking_from === "tbo" && 
                        data.tbo_response.flight_itinerary.price_changed && 
                        data.tbo_response.flight_itinerary.price_changed === 1 ? (
                            <PriceChangedModalPopup showModal={this.state.showPriceChangePopup} hidePopupModal={this.hidePriceChangePopupModal} />
                        ) : null
                    }
                    {
                        ((data.tbo_response.flight_itinerary.booking_from !== "tbo" && this.props.location.state.data.booking_status === "ticket_pending") || 
                        (data.tbo_response.flight_itinerary.booking_from !== "tbo" && this.props.location.state.bookingType && this.props.location.state.bookingType === 'drop')) ? (
                            <StatusModalPopup showModal={this.state.showKafilaConfirmationPopup} hidePopupModal={this.hidePopupModal} />
                        ) : null
                    }
                    <div className={`popup-crad confirmation-popup ${this.state.showConfirmationPopup ? 'show' : ''}`}>
                        <div className="popup-outer">
                            <div className="popup-content">
                                <div className="popup-head">
                                    <h2>Booking Confirmation</h2>
                                    <div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopup(false, null)} /></div>
                                </div>
                                <div className="popup-body popup-scorll">
                                    <div className="inner-confirmation">
                                        <p>Are you sure you want to continue?</p>
                                        <div className="popup-footer">
                                            <button className="btn btn-orange" onClick={() => this.handleConfimationAction(bookingData,successCb,errorCb,successCb1)}>Yes</button>
                                            <button className="btn btn-grey" onClick={() => this.handleConfirmationPopup(false, null)}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popup-overlay" />
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const StatusModalPopup = (props) => {
    const { showModal, hidePopupModal } = props;
        return (
            <div className={`popup-crad confirm-popup ${showModal}`}>
                <div className="popup-outer">
                    <div className="popup-content">
                        <div className="popup-head">
                            <h2>Confirm to Process</h2>
                        </div>
                        <div className="popup-scorll">
                            <div className="confirm-popup-block block">
                                <div className="block">
                                    <div className="session-exp">
                                        <h5>Your booking is under process. Kindly do not book it again, For assistance call our helpdesk.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block">
                            <div className="confirm-box-button">
                                <button className="btn btn-blue" onClick={() =>hidePopupModal()}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-overlay"></div>
            </div>
        )
    
}

const PriceChangedModalPopup = (props) => {
    const { showModal, hidePopupModal } = props;
        return (
            <div className={`popup-crad confirm-popup ${showModal}`}>
                <div className="popup-outer">
                    <div className="popup-content">
                        <div className="popup-head">
                            <h2>Price Changed</h2>
                        </div>
                        <div className="popup-scorll">
                            <div className="confirm-popup-block block">
                                <div className="block">
                                    <div className="session-exp">
                                        <h5>The price has been changed for this booking. Your booking is under process. Kindly do not book it again, For assistance call our helpdesk.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block">
                            <div className="confirm-box-button">
                                <button className="btn btn-blue" onClick={() =>hidePopupModal()}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-overlay"></div>
            </div>
        )
    
}
export default BookingConfirmComponent;
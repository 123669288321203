import React, { Component } from 'react';
import history from "../../../shared/history";
import { SingleDatePicker } from 'react-dates'
import moment from 'moment';
import { dateFormat, getCurrentDate } from '../../../shared/commonHelper';
import Select from "react-virtualized-select";
import { Field } from 'formik';
import Pagination from '../../shared/Pagination';
import FlightCancellationComponent from './FlightCancellationComponent';
import { Alert } from 'reactstrap';

class AgentTransactions extends Component {
    constructor() {
        super();
        this.state = {
            hideMsg: false,
            leftNavActive: '/my-bookings',
            url: history.location.pathname,
            date: moment(),
            focused: false,
            calendarFocused: false,
            createdAt: moment(),
            selectedAirlines: 'Any',
            originCityArray: [],
            destinationCityArray: [],
            showCreditNotePopup: null,
            showCancelPopup: false,
            cancelPopupValue: null,
        }
   }
   
    onDateChange = (createdAt) => {
        this.setState(() => ({ createdAt }));
    }
    onFocusChange = ({ focused }) => {
        this.setState(() => ({ calendarFocused: focused }))
    }
    changeDateTimeFormat(value) {
        let finalDate = moment(value).format('YYYY-MM-DD');
        return finalDate;
    }
    changeDateFormat(value) {
        let finalDate = moment(value).format('YYYY-MM-DD');
        return finalDate;
    }
    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }


    /*************************** Set Origin City Name ***************************/
    setOriginCityName = (selectedOriginCity, setFieldValue) => {
        setFieldValue('originCityFullName', selectedOriginCity.label);
        setFieldValue('originCityName', selectedOriginCity.cityName);
        setFieldValue('origin', selectedOriginCity.value);
        this.setState({ selectedOriginCity });
    }

    setDestinationCityName = (selectedDestinationCity, setFieldValue) => {
        setFieldValue('destinationCityFullName', selectedDestinationCity.label);
        setFieldValue('destinationCityName', selectedDestinationCity.cityName);
        setFieldValue('destination', selectedDestinationCity.value);
        this.setState({ selectedDestinationCity });
    }

    enterPressed = (handleSubmit, event) => { //event is not passed from methd calling as its received by default when it was binded to our context
        var code = event.keyCode || event.which;
        if (code === 13) {
            handleSubmit();
            
        }
       
    }
  
    printTicket = (data) => {

        let printTicketData = {
            "booking_id": data.booking_id,
            "tbo_booking_id": data.transaction_no,
            "pnr": data.pnr,
            "booking_type": data.booking_type,
            "jf_booking_id": data.booking_id
        }
        localStorage.setItem("print-ticket-data", JSON.stringify(printTicketData));
        window.open('/print-ticket', "_blank");
    }

    printInvoice = (data) => {
        let printInvoiceData = {
            "booking_id": data.booking_id,
            "tbo_booking_id": data.transaction_no,
            "pnr": data.pnr,
            "booking_type": data.booking_type,
            "jf_booking_id": data.booking_id
        }
        localStorage.setItem("print-invoice-data", JSON.stringify(printInvoiceData));
        window.open('/print-invoice', "_blank");
    }

    printCreditNote = (data, type) => {
        let printCreditNote = {};
        if (type === 'partial') {
            printCreditNote = data;
        } else {
            printCreditNote = {
                "booking_id": parseInt(data.booking_id)
            }
        }
        localStorage.setItem("print-credit-note-data", JSON.stringify(printCreditNote));
        window.open('/print-credit-note', "_blank");
    }

    componentDidMount() {
        // this.resetFormValues(this.props.setFieldValue);
        this.props.getBookingStatus(this.props.location.state.clickedProductCode);
        this.getOriginCitiesByFilter('default');
        this.getDestinationCitiesByFilter('default');
             
    }

    resetFormValues = (setFieldValue) => {
        let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
        setFieldValue("booking_status", 'all');
        setFieldValue("pnr_no", '');
        setFieldValue("invoice_no", '');
        setFieldValue("booking_transaction_ref", '');
        setFieldValue("passenger_name", '');
        setFieldValue("ticket_no", '');
        setFieldValue("originCityName", '');
        setFieldValue("destinationCityName", '');
        setFieldValue("airlines", 'all');
        this.setState({
            createdAt: moment(),
            date: moment(),
            selectedDestinationCity: null,
            selectedOriginCity: null,
        })
        var limitOffset = "?limit=4&offset=0&booking_status=all&start_date=" + current_date + '&end_date=' + current_date;
        this.props.getMyBookings(limitOffset);
    }

    //***************************************Start Confimation Popup********************************************/
    handleConfirmationPopup = (event, id) => {
        var element;
        if (event === true) {
            element = document.getElementById(id);
            element.classList.add('show');
        }
        else {
            element = document.getElementById(id);
            element.classList.remove('show');
        }
    }
    //***************************************End Confimation Popup*********************************************/
    callReleaseHoldPnr = (item, id) => {
        var element = document.getElementById(id);
        element.classList.remove('show');
        this.props.releaseHoldPnr(item)
    }

    callReleasePendingPnr = (item, id) => {
        var element = document.getElementById(id);
        element.classList.remove('show');
        this.props.releasePendingPnr(item)
    }

    callReleaseHoldTripJack = (item, id) => {
        var element = document.getElementById(id);
        element.classList.remove('show');
        this.props.releaseHoldTripJack(item)
    }

    callTripjackPendingPnr = (item, id) => {
        var element = document.getElementById(id);
        element.classList.remove('show');
        this.props.pnrCheckedTripJack(item)
    }

    callRiyaPendingPnr = (item, id) => {
        var element = document.getElementById(id);
        element.classList.remove('show');
        this.props.pnrCheckedRiya(item)
    }


    //*************************************** Get origin city *********************************************/
    getOriginCitiesByFilter(filterString) {
        if (filterString && filterString.length > 1) {
            const successCb = () => {
                let cityArray = [];
                if (this.props.citiesResponse.response) {
                    cityArray = this.props.citiesResponse.response.map(function (cityData, index) {
                        return ({
                            value: cityData.city_code,//set value
                            cityName: cityData.city_name,
                            label: cityData.city_full_name,
                        });
                    })
                }
                this.setState({ originCityArray: cityArray });
            }
            this.props.getCitiesAction(filterString, successCb);
        }
    }
    //************************************** Get destination city ******************************************/
    getDestinationCitiesByFilter(filterString) {
        if (filterString && filterString.length > 1) {
            const successCb = () => {
                let cityArray = [];
                if (this.props.citiesResponse.response) {
                    cityArray = this.props.citiesResponse.response.map(function (cityData, index) {
                        return ({
                            value: cityData.city_code,//set value
                            cityName: cityData.city_name,
                            label: cityData.city_full_name,
                        });
                    })
                }
                this.setState({ destinationCityArray: cityArray });
            }
            this.props.getCitiesAction(filterString, successCb);
        }
    }

    // Handle credit note popup i.e show or hide
    handleCreditNotePopup = (value, booking_id) => {
        this.setState({
            showCreditNotePopup: value
        });
        if (value) {
            this.props.partialCancellationDetail({
                "booking_id": booking_id
            })
        }
    }

    showCancelTicket = (item, popupValue) => {
        this.setState({
            showCancelPopup: popupValue,
            cancelPopupValue: item
        })
    }

   

    render() {
        const { myAccountDetails, allAirlinesData, setFieldValue, handleBlur, handleSubmit, handleChange, bookingStatusDetails, perPageLimit, currentPage } = this.props;
        const { selectedOriginCity, selectedDestinationCity, focused, date, originCityArray, destinationCityArray, createdAt } = this.state;
        let diffTime = Math.abs(new Date(createdAt) - new Date(date));
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const current_date = moment().format('YYYY-MM-DD');
        return (
           
            <React.Fragment>
                <div className="profile-detail-info">
                    
                    <div className="edit-profile-bar">
                        <div className="pull-right">
                            { /* eslint-disable-next-line */}
                            <a href="/" onClick={(e)=>e.preventDefault()} className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                        </div>
                    </div>
                    <div className="tabs-container-detail search-transation">
                        <div className="statement-info">
                            <div className="tabs-top">
                                <div className="tabs-title">
                                    <h2><i className="fa fa-exchange"></i><span>SEARCH YOUR BOOKING</span></h2>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: this.props.location.state.pageDescription }} />
                            </div>
                            <div className="statement-outer statement-transaction">
                                <div className="form-row">
                                    <div className="col-left">
                                        <div className="transtion-table-outer">
                                            <div className="table-transaction">
                                                <ul className="transaction-list">
                                                    {(myAccountDetails.loading === false) ?
                                                        myAccountDetails && myAccountDetails.response  && myAccountDetails.response.bookings.length > 0 ? myAccountDetails.response.bookings.map((item, index) => {
                                                            let ticketStatus = (
                                                                (item.booking_status === 'assign_to_refund' || item.booking_status === 'cancel_acknowledged' || item.booking_status === 'partial_cancel_pending' || item.booking_status === 'cancel_pending'
                                                                || item.booking_status === 'cancel_confirmed' || item.booking_status === 'partial_cancel_confirmed' || item.booking_status === 'cancel_rejected' || item.booking_status === 'ticket_confirmed')
                                                                ) ? 'CONFIRMED' :  (item.booking_status === 'drop_pending' || item.booking_status === 'vendor_pending') ? "PENDING" : item.booking_status.replace('_', ' ').toUpperCase();
                                                            let confirmBooking = {
                                                                "transaction_no": item.transaction_no,
                                                                "pnr": item.pnr,
                                                                "booking_status": item.booking_status,
                                                                "booking_type": item.booking_type,
                                                                'booking_id': item.booking_id,
                                                                'kafila_booking_id':item.kafila_booking_id,
                                                                "booking_from": item.booking_from
                                                            }
                                                            let confirmTripBooking = {	
                                                                "transaction_no": item.tripjack_booking_id,	
                                                                "pnr": item.pnr,	
                                                                "booking_status": item.booking_status,	
                                                                "booking_type": item.booking_type,	
                                                                'booking_id': item.booking_id,	
                                                                'kafila_booking_id':item.kafila_booking_id,
                                                                "booking_from": item.booking_from
                                                            }
                                                            let confirmRiyaBooking = {	
                                                                "transaction_no": item.transaction_no,
                                                                "riya_booking_id":  item && item.riya_booking_id ?  item.riya_booking_id :  item.transaction_no,	
                                                                "pnr": item.pnr,	
                                                                "booking_status": item.booking_status,	
                                                                "booking_type": item.booking_type,	
                                                                'booking_id': item.booking_id,	
                                                                'kafila_booking_id':item.kafila_booking_id,
                                                                "booking_from": item.booking_from,
                                                            }
                                                            return (
                                                                <li key={index}>
                                                                    <div className="col-transaction">
                                                                        <div className="col-table col-thead">
                                                                            <div className="cols col-name"><span>{item.passengers[0].first_name + ' ' + item.passengers[0].last_name}</span></div>
                                                                            <div className="cols col-hold">
                                                                                <span className="hold-tk">{ticketStatus && ticketStatus.replaceAll('_'," ")}</span>
                                                                                {/* <span className="hold-info"  style={{dispaly:'none'}}>Ticket might have been issued
                                                                    <samp>Please call <a href="#">Helpdesk 01244998999</a></samp>                                      
                                                                    </span> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-table col-tbody">
                                                                            <div className="cols col-name">
                                                                                {
                                                                                    item.passengers.map((data, newKey) => {
                                                                                        return (
                                                                                            <span key={newKey}>{(data.pax_title ? data.pax_title : '') + ' ' + data.first_name + ' ' + data.last_name}</span>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            <div className="cols">
                                                                                <div className="ticket-info">
                                                                                    <span>{item.segments[0][0].airline_code} | {item.segments[0][0].flight_no} | {dateFormat(item.segments[0][0].departure_time, 'DD MMM YYYY')}</span>

                                                                                    {
                                                                                        item.segments.map((segment, segmentKey) => {
                                                                                            return <span className="text-bold" key={segmentKey}>
                                                                                                {segment.map((newSegment, newSegmentKey) => {
                                                                                                    let destination = newSegmentKey !== 0 ? segment[newSegmentKey - 1].destination_code : '';
                                                                                                    if (destination === newSegment.origin_code)
                                                                                                        return (
                                                                                                            <React.Fragment key={newSegmentKey}>
                                                                                                                -{newSegment.destination_code}
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    else
                                                                                                        return (
                                                                                                            <React.Fragment key={newSegmentKey}>
                                                                                                                {newSegment.origin_code}-{newSegment.destination_code}
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                })}</span>
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                <div className="col-bottom">
                                                                                    <div className="book-datail"> <span>Booked On:</span> <small>{dateFormat(item.booking_date, 'FDAY, DD MMM YYYY')}</small></div>
                                                                                    {/* <div className="book-datail"><span>Last Ticketing Date:</span> <small>{dateFormat(item.segments[0][0].departure_time, 'FDAY, DD MMM YYYY')}</small></div> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className="cols col-pnr">
                                                                                <div className="inner-pnr">
                                                                                    {
                                                                                        (item.pnr !== "null" && item.pnr !== "" ) ?
                                                                                            (<div className="col-info">
                                                                                                <span className="text-bold">PNR:</span><small>{item.pnr}</small>
                                                                                            </div>) : ""
                                                                                    }
                                                                                    <div className="col-info"><span className="text-bold">Reference No:</span> <small>{item.reference_number}</small></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* {
                                                                        (item.booking_status === 'cancel_confirmed' || item.booking_status === 'partial_cancel_confirmed' || item.booking_status === 'cancel_pending' || item.booking_status === 'partial_cancel_pending') &&
                                                                        <div><b>Remark : </b>{item.remarks ? item.remarks : '-'}</div>
                                                                    } */}
                                                                    <div className="foot-block">
                                                                        {/* <span className="question-tag"><i className="icon icon-help-round-button"></i></span>  */}
                                                                        <span className="release-success" style={{ color: 'red', display: this.props.releasePnrStatus && this.props.releasePnrStatus === 'false' && item.booking_from !=='tripjack' && this.props.releasedBooking === item.booking_id ? 'block' : 'none' }}>
                                                                            {this.props.releasePnrDetail.error && this.props.releasePnrDetail.error.message}</span>

                                                                        <span className="release-success" style={{ color: 'red', display: this.props.releasePnrStatus && this.props.releasePnrStatus === 'false' && item.booking_from === 'tripjack' && this.props.releasedBooking === item.booking_id ? 'block' : 'none' }}>
                                                                            {this.props.releaseTripJackDetail.error && this.props.releaseTripJackDetail.error.message}</span>
                                                                        
                                                                        <span className="release-error" style={{color: 'green', display: this.props.releasePnrStatus && this.props.releasePnrStatus === 'true' && this.props.releasedBooking === item.booking_id ? 'block' : 'none'}}>
                                                                            PNR is successfully released.</span>
                                                                     
                                                                        {
                                                                            this.props.pnrCheckData.response && this.props.pnrCheckData.response.booking_status === 'ticket_pending' && item.kafila_booking_id !== "" &&
                                                                            <span className="release-error" style={{color: '#f4a934', display: this.props.checkPnrStatus && this.props.checkPnrStatus === 'true' && this.props.pnrBooking === item.booking_id ? 'block' : 'none'}}>
                                                                                Status is Pending. Please try again later.
                                                                            </span>
                                                                        }
                                                                        {
                                                                            this.props.pnrCheckData.response && this.props.pnrCheckData.response.booking_status === 'ticket_confirmed' && item.kafila_booking_id !== "" &&
                                                                            <span className="release-error" style={{color: 'green', display: this.props.checkPnrStatus && this.props.checkPnrStatus === 'true' && this.props.pnrBooking === item.booking_id ? 'block' : 'none'}}>
                                                                                PNR is successfully created.</span>
                                                                        }
                                                                        {
                                                                            this.props.tripJackTicketBookDetails.response && this.props.tripJackTicketBookDetails.response.booking_status === 'ticket_pending' && item.booking_from === "tripjack" &&
                                                                            <span className="release-error" style={{color: '#f4a934', display: this.props.checkPnrStatus && this.props.checkPnrStatus === 'true' && this.props.pnrBooking === item.booking_id ? 'block' : 'none'}}>
                                                                                Status is Pending. Please try again later.
                                                                            </span>
                                                                        }
                                                                        {
                                                                            this.props.tripJackTicketBookDetails.response && this.props.tripJackTicketBookDetails.response.booking_status === 'ticket_confirmed' && item.booking_from === "tripjack" &&
                                                                            <span className="release-error" style={{color: 'green', display: this.props.checkPnrStatus && this.props.checkPnrStatus === 'true' && this.props.pnrBooking === item.booking_id ? 'block' : 'none'}}>
                                                                                PNR is successfully created.</span>
                                                                        }
                                                                        {
                                                                            this.props.pnrCheckData.response && this.props.pnrCheckData.response.errorMessage &&
                                                                            this.props.pnrCheckData.response.errorMessage.data && this.props.pnrCheckData.response.errorMessage.data.content && this.props.pnrCheckData.response.errorMessage.data.content.tbo_response &&
                                                                            <span className="release-success" style={{ color: 'red', display: this.props.checkPnrStatus && this.props.checkPnrStatus === 'false' && this.props.pnrBooking === item.booking_id ? 'block' : 'none' }}>
                                                                                {this.props.pnrCheckData.response.errorMessage.data.content.tbo_response.flight_itinerary}</span>
                                                                        }
                                                                        {
                                                                            this.props.tripJackTicketBookDetails.response && this.props.tripJackTicketBookDetails.response.errorMessage &&
                                                                            this.props.tripJackTicketBookDetails.response.errorMessage.data && this.props.tripJackTicketBookDetails.response.errorMessage.data.content && this.props.tripJackTicketBookDetails.response.errorMessage.data.content.tbo_response &&
                                                                            <span className="release-success" style={{ color: 'red', display: this.props.checkPnrStatus && this.props.checkPnrStatus === 'false' && this.props.pnrBooking === item.booking_id ? 'block' : 'none' }}>
                                                                                {this.props.tripJackTicketBookDetails.response.errorMessage.data.content.tbo_response.flight_itinerary}</span>
                                                                        }

                                                                        {
                                                                            this.props.tripJackTicketBookDetails.response && this.props.tripJackTicketBookDetails.response.message &&
                                                                            <span className="release-success" style={{ color: 'red', display: this.props.checkPnrStatus && this.props.checkPnrStatus === 'false' && this.props.pnrBooking === item.booking_id ? 'block' : 'none' }}>
                                                                                {this.props.tripJackTicketBookDetails.response.message}</span>
                                                                        }
                                                                        {
                                                                            this.props.riyaTicketBookDetails.response && this.props.riyaTicketBookDetails.response.response &&this.props.riyaTicketBookDetails.response.response.data && this.props.riyaTicketBookDetails.response.response.data.booking_status === 'PENDING' && item.booking_from === "riya" &&
                                                                            <span className="release-error" style={{color: '#f4a934', display: this.props.checkPnrStatus && this.props.checkPnrStatus === 'true' && this.props.pnrBooking === item.booking_id ? 'block' : 'none'}}>
                                                                                Status is Pending. Please try again later.
                                                                            </span>
                                                                        }
                                                                        {
                                                                            this.props.riyaTicketBookDetails.response && this.props.riyaTicketBookDetails.response.booking_status === 'ticket_confirmed' && item.booking_from === "riya" &&
                                                                            <span className="release-error" style={{color: 'green', display: this.props.checkPnrStatus && this.props.checkPnrStatus === 'true' && this.props.pnrBooking === item.booking_id ? 'block' : 'none'}}>
                                                                                PNR is successfully created.</span>
                                                                        }
                                                                        {
                                                                            this.props.riyaTicketBookDetails.response && this.props.riyaTicketBookDetails.response.message &&
                                                                            <span className="release-success" style={{ color: 'red', display: this.props.checkPnrStatus && this.props.checkPnrStatus === 'false' && this.props.pnrBooking === item.booking_id ? 'block' : 'none' }}>
                                                                                {this.props.riyaTicketBookDetails.response.message}</span>
                                                                        }
                                                                        {
                                                                            (item.booking_status !== 'hold_confirmed' && item.booking_status !== 'hold_pending' && item.booking_status !== 'hold_cancelled' && item.booking_status !== 'drop_pending' && item.booking_status !== 'vendor_pending' && item.booking_status !== 'ticket_pending') &&
                                                                            <React.Fragment>
                                                                                {item.kafila_booking_id !== "" && item.booking_status === 'ticket_pending' ? '' :
                                                                                    <button className="btn btn-yellow" onClick={() => this.printInvoice(item)}><i className="fa fa-print" aria-hidden="true"></i> Invoice</button>
                                                                                }
                                                                            </React.Fragment>
                                                                        }
                                                                        {
                                                                            (item.booking_status !== 'hold_confirmed' && item.booking_status !== 'hold_pending' && item.booking_status !== 'hold_cancelled' && item.booking_status !== 'drop_pending' && item.booking_status !== 'vendor_pending' && item.booking_status !== 'ticket_pending') &&
                                                                            <React.Fragment>
                                                                                {item.kafila_booking_id !== "" && item.booking_status === 'ticket_pending' ? '' :
                                                                                    <button className="btn btn-yellow" onClick={() => this.printTicket(item)}><i className="fa fa-print" aria-hidden="true"></i> Ticket</button>
                                                                                }
                                                                            </React.Fragment>
                                                                        }
                                                                        {
                                                                            (item.booking_status === 'ticket_confirmed' || item.booking_status === 'rejected' || item.booking_status === 'partial_cancel_confirmed' || item.booking_status === 'partial_cancel_pending') &&
                                                                            <React.Fragment>
                                                                                    <button className="btn btn-red" onClick={() => this.showCancelTicket(item, true)}>Cancel</button>
                                                                            </React.Fragment>
                                                                        }
                                                                        {
                                                                            (item.booking_status === 'cancel_confirmed') &&
                                                                            <React.Fragment>
                                                                                <button className="btn btn-yellow" onClick={() => this.printCreditNote(item)}><i className="fa fa-print" aria-hidden="true"></i> Credit Note</button>
                                                                            </React.Fragment>
                                                                        }
                                                                        {
                                                                            (item.booking_status === 'partial_cancel_confirmed') &&
                                                                            <React.Fragment>
                                                                                <button className="btn btn-yellow" onClick={() => this.handleCreditNotePopup(true, item.booking_id)}><i className="fa fa-print" aria-hidden="true"></i>Credit Note(s)</button>
                                                                            </React.Fragment>
                                                                        }
                                                                        {
                                                                            item.booking_status === 'hold_confirmed' && item.booking_from !=='tripjack' &&
                                                                            <React.Fragment>
                                                                                { /* eslint-disable-next-line */}
                                                                                <a href="/" className={`btn btn-grey loading-btn ${this.props.releasePnrStatus && this.props.releasePnrStatus === 'true' && this.props.releasedBooking === item.booking_id && 'btn-disabled'}`}
                                                                                    onClick={(e) => {e.preventDefault(); this.handleConfirmationPopup(true, 'show-popup_' + index)}}><span>{
                                                                                        this.props.releasePnrStatus && this.props.releasePnrStatus === 'true' && this.props.releasedBooking === item.booking_id ?
                                                                                            'Released'
                                                                                            :
                                                                                            'Release'
                                                                                    }</span>
                                                                                    {
                                                                                        this.props.releasePnrDetail.loading && this.props.releasedBooking === item.booking_id
                                                                                            ?
                                                                                            <div className="flight-animated"><label><span><small></small><small></small><small></small></span>
                                                                                                <img src="/assets/images/flight-continue.svg" alt="" /></label></div>
                                                                                            : ''
                                                                                    }
                                                                                </a>

                                                                                {/* Popup for credit confirmation */}
                                                                          
                                                                                <div className={`popup-crad confirmation-popup`} id={'show-popup_' + index}>
                                                                                    <div className="popup-outer">
                                                                                        <div className="popup-content">
                                                                                            <div className="popup-head">
                                                                                                <h2>Release Confirmation</h2>
                                                                                                <div className="popup-close">
                                                                                                    <span 
                                                                                                        className="icon icon-cross" 
                                                                                                        onClick={() => this.handleConfirmationPopup(false, 'show-popup_' + index)} 
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="popup-body">
                                                                                                <div className="inner-confirmation">
                                                                                                    {item.booking_from === "riya" ? (
                                                                                                        <>
                                                                                                            <p>For Release hold booking. Please contact the Administrator.</p>
                                                                                                            <div className="popup-footer">
                                                                                                                <button 
                                                                                                                    className="btn btn-orange" 
                                                                                                                    onClick={() => this.handleConfirmationPopup(false, 'show-popup_' + index)}
                                                                                                                >
                                                                                                                    Close
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <p>Are you sure you want to continue?</p>
                                                                                                            <div className="popup-footer">
                                                                                                                <button 
                                                                                                                    className="btn btn-orange" 
                                                                                                                    onClick={() => this.callReleaseHoldPnr(item, 'show-popup_' + index)}
                                                                                                                >
                                                                                                                    Yes
                                                                                                                </button>
                                                                                                                <button 
                                                                                                                    className="btn btn-grey" 
                                                                                                                    onClick={() => this.handleConfirmationPopup(false, 'show-popup_' + index)}
                                                                                                                >
                                                                                                                    No
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="popup-overlay" />
                                                                                </div>
                                                                            </React.Fragment>
                                                                        }

                                                                        {
                                                                            item.booking_status === 'hold_confirmed' && item.booking_from ==='tripjack' &&
                                                                            <React.Fragment>
                                                                                { /* eslint-disable-next-line */}
                                                                                <a href="/" className={`btn btn-grey loading-btn ${this.props.releasePnrStatus && this.props.releasePnrStatus === 'true' && this.props.releasedBooking === item.booking_id && 'btn-disabled'}`}
                                                                                    onClick={(e) =>{e.preventDefault(); this.handleConfirmationPopup(true, 'show-popup_' + index)}}><span>{
                                                                                        this.props.releasePnrStatus && this.props.releasePnrStatus === 'true' && this.props.releasedBooking === item.booking_id ?
                                                                                            'Released'
                                                                                            :
                                                                                            'Release'
                                                                                    }</span>
                                                                                    {
                                                                                        this.props.releaseTripJackDetail.loading && this.props.releasedBooking === item.booking_id
                                                                                            ?
                                                                                            <div className="flight-animated"><label><span><small></small><small></small><small></small></span>
                                                                                                <img src="/assets/images/flight-continue.svg" alt="" /></label></div>
                                                                                            : ''
                                                                                    }
                                                                                </a>

                                                                                {/* Popup for credit confirmation */}
                                                                                <div className={`popup-crad confirmation-popup`} id={'show-popup_' + index}>
                                                                                    <div className="popup-outer">
                                                                                        <div className="popup-content">
                                                                                            <div className="popup-head">
                                                                                                <h2>Release Confirmation</h2>
                                                                                                <div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopup(false, 'show-popup_' + index)} /></div>
                                                                                            </div>
                                                                                            <div className="popup-body">
                                                                                                <div className="inner-confirmation">
                                                                                                    <p>Are you sure you want to continue?</p>
                                                                                                    <div className="popup-footer">
                                                                                                        <button className="btn btn-orange" onClick={() => this.callReleaseHoldTripJack(item, 'show-popup_' + index)}>Yes</button>
                                                                                                        <button className="btn btn-grey" onClick={() => this.handleConfirmationPopup(false, 'show-popup_' + index)}>No</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="popup-overlay" />
                                                                                </div>
                                                                            </React.Fragment>
                                                                        }

                                                                        {
                                                                            item.booking_status === 'ticket_pending' && item.kafila_booking_id !== "" && item.booking_from !== "tripjack" && item.booking_from !== "riya" &&
                                                                            <React.Fragment>
                                                                                 
                                                                                 {
                                                                                     item.booking_status === 'ticket_failed' ?'':
                                                                                      /* eslint-disable-next-line */
                                                                                     <a href="/" className={`btn btn-grey loading-btn ${this.props.checkPnrStatus && this.props.checkPnrStatus === 'true'
                                                                                            && this.props.pnrBooking === item.booking_id && 'btn-disabled'}`}
                                                                                            onClick={(e) => {e.preventDefault(); this.handleConfirmationPopup(true, 'show-popup_' + index)}}><span>{
                                                                                                this.props.checkPnrStatus && this.props.checkPnrStatus === 'true' && this.props.pnrBooking=== item.booking_id ?
                                                                                                    'Status Checked'
                                                                                                    :
                                                                                                    'Check Status'
                                                                                            }</span>
                                                                                            {
                                                                                                this.props.pnrCheckData.loading && this.props.pnrBooking === item.booking_id
                                                                                                    ?
                                                                                                    <div className="flight-animated"><label><span><small></small><small></small><small></small></span>
                                                                                                        <img src="/assets/images/flight-continue.svg" alt="" /></label></div>
                                                                                                    : ''
                                                                                            }
                                                                                        </a>
                                                                                }
                                                                                {/* Popup for credit confirmation */}
                                                                                <div className={`popup-crad confirmation-popup`} id={'show-popup_' + index}>
                                                                                    <div className="popup-outer">
                                                                                        <div className="popup-content">
                                                                                            <div className="popup-head">
                                                                                                <h2>PNR Check Confirmation</h2>
                                                                                                <div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopup(false, 'show-popup_' + index)} /></div>
                                                                                            </div>
                                                                                            <div className="popup-body popup-scorll">
                                                                                                <div className="inner-confirmation">
                                                                                                    <p>Are you sure you want to continue?</p>
                                                                                                    <div className="popup-footer">
                                                                                                        <button className="btn btn-orange" onClick={() => this.callReleasePendingPnr(item, 'show-popup_' + index)}>Yes</button>
                                                                                                        <button className="btn btn-grey" onClick={() => this.handleConfirmationPopup(false, 'show-popup_' + index)}>No</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="popup-overlay" />
                                                                                </div>
                                                                            </React.Fragment>
                                                                        }
                                                                        {
                                                                            item.booking_status === 'ticket_pending' && item.booking_from === "tripjack" &&
                                                                            <React.Fragment>
                                                                                 
                                                                                 {
                                                                                     item.booking_status === 'ticket_failed' ?'':
                                                                                      /* eslint-disable-next-line */
                                                                                     <a href="/" className={`btn btn-grey loading-btn ${this.props.checkPnrStatus && this.props.checkPnrStatus === 'true'
                                                                                            && this.props.pnrBooking === item.booking_id && 'btn-disabled'}`}
                                                                                            onClick={(e) => {e.preventDefault(); this.handleConfirmationPopup(true, 'show-popup_' + index)}}><span>{
                                                                                                this.props.checkPnrStatus && this.props.checkPnrStatus === 'true' && this.props.pnrBooking=== item.booking_id ?
                                                                                                    'Status Checked'
                                                                                                    :
                                                                                                    'Check Status'
                                                                                            }</span>
                                                                                            {
                                                                                                this.props.ticketBookingData.loading && this.props.pnrBooking === item.booking_id
                                                                                                    ?
                                                                                                    <div className="flight-animated"><label><span><small></small><small></small><small></small></span>
                                                                                                        <img src="/assets/images/flight-continue.svg" alt="" /></label></div>
                                                                                                    : ''
                                                                                            }
                                                                                        </a>
                                                                                }
                                                                                {/* Popup for credit confirmation */}
                                                                                <div className={`popup-crad confirmation-popup`} id={'show-popup_' + index}>
                                                                                    <div className="popup-outer">
                                                                                        <div className="popup-content">
                                                                                            <div className="popup-head">
                                                                                                <h2>PNR Check Confirmation</h2>
                                                                                                <div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopup(false, 'show-popup_' + index)} /></div>
                                                                                            </div>
                                                                                            <div className="popup-body popup-scorll">
                                                                                                <div className="inner-confirmation">
                                                                                                    <p>Are you sure you want to continue?</p>
                                                                                                    <div className="popup-footer">
                                                                                                        <button className="btn btn-orange" onClick={() => this.callTripjackPendingPnr(item, 'show-popup_' + index)}>Yes</button>
                                                                                                        <button className="btn btn-grey" onClick={() => this.handleConfirmationPopup(false, 'show-popup_' + index)}>No</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="popup-overlay" />
                                                                                </div>
                                                                            </React.Fragment>
                                                                        }
                                                                        {
                                                                            (item.booking_status === 'ticket_pending' || item.booking_status === 'hold_pending') && item.booking_from === "riya" &&
                                                                            <React.Fragment>
                                                                                 
                                                                                 {
                                                                                     (item.booking_status === 'ticket_pending' || item.booking_status === 'hold_pending') ?
                                                                                      /* eslint-disable-next-line */
                                                                                     <a href="javascript:void(0);" className={`btn btn-grey loading-btn ${this.props.checkPnrStatus && this.props.checkPnrStatus === 'true'
                                                                                            && this.props.pnrBooking === item.booking_id && 'btn-disabled'}`}
                                                                                            onClick={() => this.handleConfirmationPopup(true, 'show-popup_' + index)}><span>{
                                                                                                this.props.checkPnrStatus && this.props.checkPnrStatus === 'true' && this.props.pnrBooking=== item.booking_id ?
                                                                                                    'Status Checked'
                                                                                                    :
                                                                                                    'Check Status'
                                                                                            }</span>
                                                                                            {
                                                                                                this.props.ticketBookingData.loading && this.props.pnrBooking === item.booking_id
                                                                                                    ?
                                                                                                    <div className="flight-animated"><label><span><small></small><small></small><small></small></span>
                                                                                                        <img src="/assets/images/flight-continue.svg" alt="" /></label></div>
                                                                                                    : ''
                                                                                            }
                                                                                        </a> : ''
                                                                                }
                                                                                {/* Popup for credit confirmation */}
                                                                                <div className={`popup-crad confirmation-popup`} id={'show-popup_' + index}>
                                                                                    <div className="popup-outer">
                                                                                        <div className="popup-content">
                                                                                            <div className="popup-head">
                                                                                                <h2>PNR Check Confirmation</h2>
                                                                                                <div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopup(false, 'show-popup_' + index)} /></div>
                                                                                            </div>
                                                                                            <div className="popup-body popup-scorll">
                                                                                                <div className="inner-confirmation">
                                                                                                    <p>Are you sure you want to continue?</p>
                                                                                                    <div className="popup-footer">
                                                                                                        <button className="btn btn-orange" onClick={() => this.callRiyaPendingPnr(item, 'show-popup_' + index)}>Yes</button>
                                                                                                        <button className="btn btn-grey" onClick={() => this.handleConfirmationPopup(false, 'show-popup_' + index)}>No</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="popup-overlay" />
                                                                                </div>
                                                                            </React.Fragment>
                                                                        }
                                                                        {
                                                                            item.booking_from ===  "tripjack" &&
                                                                            <button className="btn btn-green" onClick={() => {
                                                                                this.props.history.push({
                                                                                    pathname: "/booking",
                                                                                    state: {
                                                                                        data: confirmTripBooking,
                                                                                        pageUrl: 'my-bookings',
    
                                                                                    }
                                                                                });
                                                                            }}>Open</button>
                                                                        }
                                                                        {
                                                                            item.booking_from ===  "riya" &&
                                                                            <button className="btn btn-green" onClick={() => {
                                                                                this.props.history.push({
                                                                                    pathname: "/booking",
                                                                                    state: {
                                                                                        data: confirmRiyaBooking,
                                                                                        pageUrl: 'my-bookings',
    
                                                                                    }
                                                                                });
                                                                            }}>Open</button>
                                                                        }
                                                                        {
                                                                            item.booking_from !==  "tripjack" && item.booking_from !==  "riya" &&
                                                                            <button className="btn btn-green" onClick={() => {
                                                                                this.props.history.push({
                                                                                    pathname: "/booking",
                                                                                    state: {
                                                                                        data: confirmBooking,
                                                                                        pageUrl: 'my-bookings',
    
                                                                                    }
                                                                                });
                                                                            }}>Open</button>
                                                                        }
                                                                    </div>
                                                                </li>
                                                            )

                                                        }) : <div className="no-result-wrap"><div className="result-wrap-outer"><div className="block"><div className="no-result-box"><h2>No results found</h2><p>We can’t find any item matching your search.</p></div></div></div></div>
                                                        : <div className="loader relative-loader" style={{ "display": "block" }}><div className="loader-content"><div className="loader-ring blue-ring"> <span></span> </div></div></div>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-right">
                                        <div className="search-transaction-form form-sec-block">
                                            <h3 className="title-search">Filter</h3>
                                            <div className="search-form">
                                                <form>
                                                    {
                                                       diffDays > 31 ? <Alert color="danger">You can search booking of maximum 31 days.</Alert> : ''
                                                    }
                                                    <div className="form-row">
                                                        {/* <div className="col-sm-12 col-lg-12">
                                        <div className="form-group">
                                            <label className="control-label">USER TYPE:</label>
                                            <div className="custom-select-box">
                                            <select className="form-control custom-filed" name="user_type" onBlur={handleBlur} onChange={handleChange}>
                                                <option  value="all">All</option>
                                                {
                                                    userTypeDetails.response && userTypeDetails.response.user_types.map((item,key) => {
                                                        return (
                                                            <option key={key} value={item.user_type_id}>{item.user_type}</option>
                                                        );
                                                    })  
                                                } 
                                            </select>
                                            </div>
                                        </div>
                                    </div> */}
                                                        {/* <div className="col-sm-12 col-lg-12">
                                        <div className="form-group">
                                            <label className="control-label">TRANSACTION TYPE:</label>
                                            <div className="custom-select-box">
                                            <select className="form-control custom-filed" name="transaction_type" onBlur={handleBlur} onChange={handleChange}>
                                                <option  value="all">All</option>
                                                {
                                                    transactionTypeDetails.response && transactionTypeDetails.response.transaction_type.map((item,key) => {
                                                        return (
                                                            <option key={key} value={item.transaction_type}>{item.transaction_type}</option>
                                                        );
                                                    })  
                                                }
                                            </select>
                                            </div>
                                        </div>
                                    </div> */}
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">BOOKING STATUS:</label>
                                                                <div className="custom-select-box">
                                                                    <select className="form-control custom-filed" name="booking_status" value={this.props.values.booking_status} onBlur={handleBlur} onChange={handleChange} onKeyPress={this.enterPressed.bind(this, handleSubmit)}>
                                                                        <option value="all">All</option>
                                                                        {
                                                                            bookingStatusDetails.response && bookingStatusDetails.response.booking_status.map((item, key) => {
                                                                                return (
                                                                                    <option key={key} value={item.status}>{item.status_name}</option>
                                                                                );
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-sm-12 col-lg-12">
                                        <div className="form-group">
                                            <label className="control-label">BOOKING TYPE:</label>
                                            <div className="custom-select-box">
                                            <select className="form-control custom-filed" name="booking_type" onBlur={handleBlur} onChange={handleChange}>
                                                <option  value="all">All</option>
                                                <option  value="online">Online Booking</option>
                                                <option  value="offline">Offline Booking</option>
                                            </select>
                                            </div>
                                        </div>
                                    </div> */}



                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">

                                                                <label className="control-label">Booking Start Date :</label>
                                                                <div className="input-group input-addon single-calendar">
                                                                    {
                                                                        <SingleDatePicker
                                                                            date={createdAt}
                                                                            onDateChange={createdAt => {
                                                                                this.setState({ createdAt });
                                                                                setFieldValue("start_date", this.changeDateTimeFormat(createdAt));
                                                                            }}
                                                                            focused={this.state.calendarFocused}
                                                                            onFocusChange={this.onFocusChange}
                                                                            id="start_date"
                                                                            displayFormat="DD-MM-YYYY"
                                                                            readOnly={true}
                                                                            showDefaultInputIcon={true}
                                                                            inputIconPosition={"after"}
                                                                            isOutsideRange={() => false}
                                                                            hideKeyboardShortcutsPanel={true}
                                                                            isDayHighlighted={day => day.isSame(current_date, 'day')}
                                                                            reopenPickerOnClearDate={true}
                                                                            withPortal={true}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">Booking End Date :</label>
                                                                <div className="input-group input-addon single-calendar">
                                                                    {
                                                                        <SingleDatePicker
                                                                            date={date}
                                                                            onDateChange={date => {
                                                                                this.setState({ date });
                                                                                setFieldValue("end_date", this.changeDateFormat(date));
                                                                            }}
                                                                            focused={focused}
                                                                            onFocusChange={({ focused }) => this.setState({ focused })}
                                                                            id="end_date"
                                                                            displayFormat="DD-MM-YYYY"
                                                                            readOnly={true}
                                                                            showDefaultInputIcon={true}
                                                                            inputIconPosition={"after"}
                                                                            isOutsideRange={() => false}
                                                                            hideKeyboardShortcutsPanel={true}
                                                                            isDayHighlighted={day => day.isSame(current_date, 'day')}
                                                                            reopenPickerOnClearDate={true}
                                                                            withPortal={true}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">PNR NO:</label>
                                                                <Field className="form-control custom-filed" type="text" name="pnr_no" onKeyPress={this.enterPressed.bind(this, handleSubmit)} onKeyUp={() => {this.setState({
                                                                    createdAt: null,
                                                                    date: null
                                                                })}} />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">Ticket NO:</label>
                                                                <Field className="form-control custom-filed" type="text" name="ticket_no" onKeyPress={this.enterPressed.bind(this, handleSubmit)} onKeyUp={() => {this.setState({
                                                                    createdAt: null,
                                                                    date: null
                                                                })}}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">Reference NO:</label>
                                                                <Field className="form-control custom-filed" type="text" name="booking_transaction_ref" onKeyPress={this.enterPressed.bind(this, handleSubmit)} onKeyUp={() => {this.setState({
                                                                    createdAt: null,
                                                                    date: null
                                                                })}}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">PASSENGER NAME:</label>
                                                                <Field className="form-control custom-filed" type="text" name="passenger_name" onKeyPress={this.enterPressed.bind(this, handleSubmit)} />

                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">Invoice NO:</label>
                                                                <Field className="form-control custom-filed" type="text" name="invoice_no" onKeyPress={this.enterPressed.bind(this, handleSubmit)} onKeyUp={() => {this.setState({
                                                                    createdAt: null,
                                                                    date: null
                                                                })}} />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">Origin:</label>
                                                                <div className="custom-select-box">
                                                                    <Select
                                                                        component={Select}
                                                                        value={selectedOriginCity}
                                                                        options={originCityArray}
                                                                        name="origin"
                                                                        className="custom-select-box"
                                                                        onBlur={this.originHandleBlur}
                                                                        onChange={(event) => { this.setOriginCityName(event, setFieldValue) }}
                                                                        onInputChange={(event) => { this.getOriginCitiesByFilter(event) }}
                                                                        placeholder="Select Origin City"
                                                                        openMenuOnFocus="true"
                                                                        // tabSelectsValue="false"
                                                                        defaultMenuIsOpen={true}
                                                                    >
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">Destination:</label>
                                                                <div className="custom-select-box">
                                                                    <Select
                                                                        component={Select}
                                                                        value={selectedDestinationCity}
                                                                        options={destinationCityArray}
                                                                        name="destination"
                                                                        className="custom-select-box"
                                                                        onBlur={this.destinationHandleBlur}
                                                                        onChange={(event) => { this.setDestinationCityName(event, setFieldValue) }}
                                                                        onInputChange={(event) => { this.getDestinationCitiesByFilter(event) }}
                                                                        placeholder="Select Destination City"
                                                                        openMenuOnFocus="true"
                                                                        tabIndex="0"
                                                                    // tabSelectsValue="false"
                                                                    >
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">Airline:</label>
                                                                <div className="custom-select-box">
                                                                    <select className="form-control custom-filed" name="airlines" value={this.props.values.airlines} onBlur={handleBlur} onChange={handleChange} onKeyPress={this.enterPressed.bind(this, handleSubmit)}>
                                                                        <option value="all">All</option>
                                                                        {
                                                                            allAirlinesData.response && allAirlinesData.response.map((item, key) => {
                                                                                return (
                                                                                    <option key={key} value={item.airline_name}>{item.airline_name}</option>
                                                                                );
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="btn-block text-center">
                                                        {diffDays && diffDays >31 ?
                                                                <button type="button" className="btn btn-sky-blue" onClick={handleSubmit} disabled>Search</button>
                                                            :
                                                                <button type="button" className="btn btn-sky-blue" onClick={handleSubmit}>Search</button>
                                                        }
                                                        <button type="button" className="btn btn-grey" onClick={() => this.resetFormValues(setFieldValue)}>Cancel</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-footer">
                        <Pagination
                            totalRecord={myAccountDetails.response && myAccountDetails.response.total_count}
                            pageLimit={perPageLimit}
                            activePage={currentPage}
                            getRecordsPerPage={(value) => this.props.getRecordsPerPage(value, this.props.values)} />
                    </div>
                    {
                        this.creditNotePopup()
                    }
                    {
                        this.state.showCancelPopup && this.state.cancelPopupValue && !this.state.cancelPopupValue.kafila_booking_id &&  this.state.cancelPopupValue.booking_from !== 'efly' && this.state.cancelPopupValue.booking_from !== 'gofly' && this.state.cancelPopupValue.booking_from !== 'satkar' && this.state.cancelPopupValue.booking_from !== 'fareboutique' && this.state.cancelPopupValue.booking_from !== 'airiq' &&  this.state.cancelPopupValue.booking_type === 'online' &&
                        <FlightCancellationComponent {...this.props} {...this.state} showCancelTicket={this.showCancelTicket} />
                    }
                    {
                        this.state.showCancelPopup && this.state.cancelPopupValue && this.state.cancelPopupValue.kafila_booking_id && this.state.cancelPopupValue.booking_from !== 'efly' && this.state.cancelPopupValue.booking_from !== 'gofly' &&  this.state.cancelPopupValue.booking_from !== 'satkar' &&  this.state.cancelPopupValue.booking_from !== 'fareboutique' && this.state.cancelPopupValue.booking_from !== 'airiq' &&   this.state.cancelPopupValue.booking_from !== 'tripjack' && this.state.cancelPopupValue.booking_from !== 'tbo' &&   this.state.cancelPopupValue.booking_from !== 'riya' && this.state.cancelPopupValue.booking_type === 'online' &&
                        <FlightCancellationComponent {...this.props} {...this.state} showCancelTicket={this.showCancelTicket} />
                    }
                    {
                       (this.state.showCancelPopup && this.state.cancelPopupValue && (this.state.cancelPopupValue.booking_type === 'offline' ||  this.state.cancelPopupValue.booking_from === 'efly' || this.state.cancelPopupValue.booking_from === 'gofly' ||  this.state.cancelPopupValue.booking_from === 'satkar' || this.state.cancelPopupValue.booking_from === 'fareboutique' || this.state.cancelPopupValue.booking_from === 'airiq' || (this.state.cancelPopupValue.kafila_booking_id && this.state.cancelPopupValue.kafila_booking_id !== "" && this.state.cancelPopupValue.booking_type === 'online' && this.state.cancelPopupValue.booking_from !== 'kafila'))) &&
                        <div className={`popup-crad confirmation-popup show`}>
                            <div className="popup-outer">
                                <div className="popup-content">
                                    <div className="popup-head">
                                        <h2>Cancel request</h2>
                                        <div className="popup-close"><span className="icon icon-cross" onClick={() => this.setState({
                                            showCancelPopup: false
                                        })} /></div>
                                    </div>
                                    <div className="popup-body popup-scorll">
                                        <div className="inner-confirmation">
                                            <p>Please contact to helpdesk for cancellation</p>
                                            <div className="popup-footer">
                                                <button className="btn btn-orange" onClick={() => this.setState({
                                                    showCancelPopup: false
                                                })}>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="popup-overlay" />
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }

    /* credit note popup for partial cancellation */
    creditNotePopup = () => {
        return (
            <div className={`popup-crad confirmation-popup ${this.state.showCreditNotePopup ? 'show' : ''}`}>
                <div className="popup-outer">
                    <div className="popup-content">
                        <div className="popup-head">
                            <h2>Click to view Credit Note</h2>
                            <div className="popup-close"></div>
                        </div>
                        <div className="popup-body popup-scorll">
                            <ul className="list-select">
                                {
                                    this.props.partailCancellationInfo.response && this.props.partailCancellationInfo.response.details &&
                                    this.props.partailCancellationInfo.response.details.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                { /* eslint-disable-next-line */}
                                                <li><a href="/" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.printCreditNote({
                                                    'booking_id': item.booking_id,
                                                    'cancel_id': item.cancel_id
                                                }, 'partial')}} >{(Number(index) + 1) + '. ' + item.pax_names.join(', ') + '  -  ' + item.segments.join(', ')}</a></li>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </ul>
                            <div className="popup-footer">
                                <button className="btn btn-orange" onClick={() => this.handleCreditNotePopup(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-overlay" />
            </div>
        )
    }
}

export default (AgentTransactions)
import React, { Component } from 'react';
import 'react-dates/initialize';
import moment from 'moment';
import { Field } from 'formik';
import { SingleDatePicker } from 'react-dates'
//import Select from 'react-select';
import Select from "react-virtualized-select";
import { dateFormat, getCurrentDate } from '../../shared/commonHelper';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css'; 
let isStateChange=''
class FlightMultiCitySearchActiveComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            searchingData :props.searchQuery ? props.searchQuery.segments :[],
            idDisplayError:'',
            adultCount: props.values.adult_count,
            childCount: props.values.child_count,
            infantCount: props.values.infant_count,
            date: moment(getCurrentDate()),//for depart date
            startDate: moment(getCurrentDate()),//for depart date
            endDate:null,//for return date
            focused:false,
            selectedOriginCity: props.searchQuery 
                                ? 
                                null
                                : 
                                {value: "JAI", label: <React.Fragment><span className="pull-right">JAI</span><samp>Jaipur, India</samp> </React.Fragment>, cityName: 'Jaipur', countryName: 'India'},
                    selectedDestinationCity: props.searchQuery 
                                ? 
                                null
                                : 
                                null,
            selectedAirlines: [],
            selectedCabinClass: props.searchQuery 
                                ? 
                                {value: props.searchQuery.flight_cabin_class, label: props.searchQuery.flightCabinClassName}
                                : 
                                {value: "1", label: "Any"},
            editOptionSegments: [],
            calenderDate_0: false,
            calenderDate_1: false,
            calenderDate_2: false,
            calenderDate_3: false,
            calenderDate_4: false,
            calenderDate_5: false,
            calenderDate_6: false,
            tempDateArray: [],
            originCityArray: [],
            destinationCityArray: [],
            selectedFareType    : props.searchQuery 
                                ? 
                                props.searchQuery.pft
                                : "REGULAR",
        }
        this.isOutsideRange = this.isOutsideRange.bind(this);
    }

    //increment and decrement adult 
    onAdultClick = (type, setFieldValue) => {
        let oldcount =  this.state.adultCount;
        let newcount = (type === 'add') ? ((oldcount < 9) ? (oldcount + 1) : oldcount ) : ((oldcount > 0) ? (oldcount - 1) : 0 );
        this.setState({adultCount: newcount});
        setFieldValue("adult_count", newcount );
    }

    //increment and decrement child
    onChildClick = (type, setFieldValue) => {
        let oldcount =  this.state.childCount;
        let newcount = (type === 'add') ? ((oldcount < 8) ? (oldcount + 1) : oldcount ) : ((oldcount > 0) ? (oldcount - 1) : 0 );
        this.setState({childCount: newcount});
        setFieldValue("child_count", newcount );
    }

    //increment and decrement infant
    onInfantClick = (type, setFieldValue) => {
        let oldcount =  this.state.infantCount;
        let newcount = (type === 'add') ? ((oldcount < 8) ? (oldcount + 1) : oldcount ) : ((oldcount > 0) ? (oldcount - 1) : 0 );
        this.setState({infantCount: newcount});
        setFieldValue("infant_count", newcount );
    }

    changeDateFormat(value)
    {
        let finalDate = moment(value).format('YYYY-MM-DD')+'T00:00:00';
        return finalDate;
    }

    /*************************** Set Origin City Name ***************************/
    setOriginCityName = (selectedOriginCity, setFieldValue) =>
    {
        setFieldValue('originCityFullName', selectedOriginCity.labelValue);
        setFieldValue('originCityName', selectedOriginCity.cityName);
        setFieldValue('origin', selectedOriginCity.newValue);
        setFieldValue('originCountryName', selectedOriginCity.countryName);
        setFieldValue('originAirportCode', selectedOriginCity.airportCode);
        
        setFieldValue('old_originCityFullName', selectedOriginCity.labelValue ? selectedOriginCity.labelValue : 'Jaipur, India');
        setFieldValue('old_originCityName', selectedOriginCity.cityName ? selectedOriginCity.cityName : 'Jaipur');
        setFieldValue('old_origin', selectedOriginCity.newValue ? selectedOriginCity.newValue : 'JAI');
        setFieldValue('old_originCountryName', selectedOriginCity.countryName ? selectedOriginCity.countryName : 'India');
        setFieldValue('old_originAirportCode', selectedOriginCity.airportCode ? selectedOriginCity.airportCode : 'JAI');
        this.setState({ selectedOriginCity });
    }

    /*************************** Set Destination City Name ***************************/
    setDestinationCityName = (selectedDestinationCity, setFieldValue) =>
    {
        setFieldValue('destinationCityFullName', selectedDestinationCity.labelValue);
        setFieldValue('destinationCityName', selectedDestinationCity.cityName);
        setFieldValue('destination', selectedDestinationCity.newValue);
        setFieldValue('destinationAirportCode', selectedDestinationCity.airportCode);
        setFieldValue('destinationCountryName', selectedDestinationCity.countryName);

        setFieldValue('old_destinationCityFullName', selectedDestinationCity.labelValue);
        setFieldValue('old_destinationCityName', selectedDestinationCity.cityName);
        setFieldValue('old_destination', selectedDestinationCity.newValue);
        setFieldValue('old_destinationAirportCode', selectedDestinationCity.airportCode);
        setFieldValue('old_destinationCountryName', selectedDestinationCity.countryName);
        this.setState({ selectedDestinationCity });
    }

    /*************************** Set Selected Airlines *******************************/
    setSelectedAirlines = (selectedAirlines, setFieldValue) =>
    {
        if(this.props.searchQuery && this.props.searchQuery.source)
        {
            delete this.props.searchQuery.source;
        }
        this.setState({ selectedAirlines });
        let selectSource = [];
        for(let i = 0;i< selectedAirlines.length ; i++)
        {
            selectSource.push(selectedAirlines[i].value);
        }
        setFieldValue('source', selectSource);
    }


    /*************************** Set Selected Cabin Class *******************************/
    setSelectedCabinClass = (selectedCabinClass, setFieldValue) =>
    {
        let searchingData = this.state.searchingData;
        searchingData && searchingData.length && searchingData.map((data,index)=>{
            searchingData[index].flight_cabin_class = selectedCabinClass.value;
            return null;
        })

        this.setState({
            searchingData: searchingData,
        })
        this.setState({ selectedCabinClass });
        setFieldValue('flight_cabin_class', selectedCabinClass.value);
        setFieldValue('flightCabinClassName', selectedCabinClass.label);
    }

    /*******************************Function to save Itinerary*******************************/
    addToItinerary = (values,selectedOriginCity,selectedDestinationCity,segments) => {
       
        this.setState({idDisplayError:""})
        if(selectedOriginCity === '' && selectedDestinationCity === '')
        {
            this.props.setErrors({
                'origin' : 'Departure city is required.',
                'destination' : 'Destination city is required.'
            });
        }else if(selectedOriginCity === '')
        {
            this.props.setErrors({'origin' : 'Departure city is required.' }); 
        }else if(selectedDestinationCity === '')
        {
            this.props.setErrors({'destination' : 'Destination city is required.' });
        }
        else if(selectedOriginCity !== '' && selectedDestinationCity !== '')
        {
            if(values.origin !== values.destination)
            {
                if(values.destination === '' || !values.destination){
                    this.props.setErrors({'destination' : 'Destination city is required.'});
                    //this.props.setTouched({'destination':true});
                }
                if(values.origin === '' || !values.origin){
                    this.props.setErrors({'origin' : 'Departure city is required.'});
                    //this.props.setTouched({'origin':true});
                }
            
                if(values.origin !== '' && values.destination !== '' && values.destination && values.origin){
                    let data = this.state.searchingData;
                    if(data.length < 7){
                        let segment = {
                            "origin":values.origin,
                            "originCityName":values.originCityName,
                            "originCityFullName":values.originCityFullName,
                            "destination":values.destination,
                            "destinationCityName": values.destinationCityName,
                            "destinationCityFullName": values.destinationCityFullName,
                            "preferred_departure_time":values.first_preferred_departure_time,
                            "preferred_arrival_time":values.first_preferred_arrival_time,
                            "flight_cabin_class":values.flight_cabin_class,
                            "originAirportCode": values.originAirportCode,
                            "destinationAirportCode": values.destinationAirportCode,
                            "originCountryName": values.originCountryName,
                            "destinationCountryName": values.destinationCountryName,

                            "old_origin":values.old_origin ? values.old_origin :values.origin,
                            "old_originCityName":values.old_originCityName ? values.old_originCityName : values.originCityName,
                            "old_originCityFullName":values.old_originCityFullName ? values.old_originCityFullName :values.originCityFullName,
                            "old_destination":values.old_destination,
                            "old_destinationCityName": values.old_destinationCityName,
                            "old_destinationCityFullName": values.old_destinationCityFullName,
                            "old_originAirportCode": values.old_originAirportCode ? values.old_originAirportCode : values.originAirportCode,
                            "old_destinationAirportCode": values.old_destinationAirportCode,
                            "old_originCountryName": values.old_originCountryName ? values.old_originCountryName : values.originCountryName,
                            "old_destinationCountryName": values.old_destinationCountryName

                        }
                        data.push(segment);
                        this.setState({
                            searchingData: data,
                        })
                        this.props.setFieldValue('multiCityCount',parseInt(values.multiCityCount)+1);
                        this.setState({selectedOriginCity: ''});
                        this.setState({selectedDestinationCity: ''});
                        this.props.setErrors({'destination' : ''});
                        this.props.setErrors({'origin' : ''});
                    }else{
                        alert('Sorry, You cannot select more than 7 Segment.');
                    }
                }
            }else{
                this.props.setErrors({
                    'origin' : 'Departure city and Destination city must be different.',
                    'destination' : 'Departure city and Destination city must be different.'
                });
            }
      }
       
    }
    Itinerary=()=>{
        this.setState({idDisplayError:"Please Select Valid Date"})
    }
    /******************************* Show edit option Save/Ignore/Delete *******************************/
    showEditOptions = (index) => {
        let segmentCountArr = this.state.editOptionSegments;
        segmentCountArr.push(index);
        this.setState({editOptionSegments: segmentCountArr});
    }

    /******************************* Delete segments *******************************/   
    deleteSegments = (index) => {
        this.state.searchingData.splice(index, 1);
        this.setState({
            searchingData: this.state.searchingData,
        });
        this.props.setFieldValue('multiCityCount',parseInt(this.props.values.multiCityCount)-1)
    }

    /******************************* Ignore segments *******************************/   
    actionIgnoreSaveSegments = (segmentIndex,action, oldDate, newDate,setFieldValue) => {
        let searchingData = this.state.searchingData;
        if(action === 'save')
        {
            let index = this.state.editOptionSegments.indexOf(segmentIndex);
            this.state.editOptionSegments.splice(index, 1);
            this.setState({editOptionSegments: this.state.editOptionSegments});
            searchingData[segmentIndex].old_preferred_arrival_time = this.state.searchingData[segmentIndex].preferred_arrival_time;

             if (this.state.searchingData[segmentIndex].old_originCityFullName) {
                searchingData[segmentIndex].old_originCityFullName = this.state.searchingData[segmentIndex].old_originCityFullName;
                searchingData[segmentIndex].old_originCityName = this.state.searchingData[segmentIndex].old_originCityName;
                searchingData[segmentIndex].old_origin = this.state.searchingData[segmentIndex].old_origin;
                searchingData[segmentIndex].old_originCountryName = this.state.searchingData[segmentIndex].old_originCountryName;
                searchingData[segmentIndex].old_originAirportCode = this.state.searchingData[segmentIndex].old_originAirportCode;

                searchingData[segmentIndex].originCityFullName = this.state.searchingData[segmentIndex].old_originCityFullName;
                searchingData[segmentIndex].originCityName = this.state.searchingData[segmentIndex].old_originCityName;
                searchingData[segmentIndex].origin = this.state.searchingData[segmentIndex].old_origin;
                searchingData[segmentIndex].originCountryName = this.state.searchingData[segmentIndex].old_originCountryName;
                searchingData[segmentIndex].originAirportCode = this.state.searchingData[segmentIndex].old_originAirportCode;
             }
             if (this.state.searchingData[segmentIndex].old_destinationCityFullName) {
                searchingData[segmentIndex].old_destinationCityFullName = this.state.searchingData[segmentIndex].old_destinationCityFullName;
                searchingData[segmentIndex].old_destinationCityName = this.state.searchingData[segmentIndex].old_destinationCityName;
                searchingData[segmentIndex].old_destination = this.state.searchingData[segmentIndex].old_destination;
                searchingData[segmentIndex].old_destinationCountryName = this.state.searchingData[segmentIndex].old_destinationCountryName;
                searchingData[segmentIndex].old_destinationAirportCode = this.state.searchingData[segmentIndex].old_destinationAirportCode;

                searchingData[segmentIndex].destinationCityFullName = this.state.searchingData[segmentIndex].old_destinationCityFullName;
                searchingData[segmentIndex].destinationCityName = this.state.searchingData[segmentIndex].old_destinationCityName;
                searchingData[segmentIndex].destination = this.state.searchingData[segmentIndex].old_destination;
                searchingData[segmentIndex].destinationCountryName = this.state.searchingData[segmentIndex].old_destinationCountryName;
                searchingData[segmentIndex].destinationAirportCode = this.state.searchingData[segmentIndex].old_destinationAirportCode;
             }
        }
        else if(action === 'ignore')
        {
            if(this.state.searchingData[segmentIndex].old_preferred_arrival_time)
            {
                searchingData[segmentIndex].preferred_departure_time = this.state.searchingData[segmentIndex].old_preferred_arrival_time;
                searchingData[segmentIndex].preferred_arrival_time = this.state.searchingData[segmentIndex].old_preferred_arrival_time;
            }
            if (this.state.searchingData[segmentIndex].old_originCityFullName) {
                searchingData[segmentIndex].old_originCityFullName = this.state.searchingData[segmentIndex].originCityFullName;
                searchingData[segmentIndex].old_originCityName = this.state.searchingData[segmentIndex].originCityName;
                searchingData[segmentIndex].old_origin = this.state.searchingData[segmentIndex].origin;
                searchingData[segmentIndex].old_originCountryName = this.state.searchingData[segmentIndex].originCountryName;
                searchingData[segmentIndex].old_originAirportCode = this.state.searchingData[segmentIndex].originAirportCode;
            }
            if (this.state.searchingData[segmentIndex].old_destinationCityFullName) {
                searchingData[segmentIndex].old_destinationCityFullName = this.state.searchingData[segmentIndex].destinationCityFullName;
                searchingData[segmentIndex].old_destinationCityName = this.state.searchingData[segmentIndex].destinationCityName;
                searchingData[segmentIndex].old_destination = this.state.searchingData[segmentIndex].destination;
                searchingData[segmentIndex].old_destinationCountryName = this.state.searchingData[segmentIndex].destinationCountryName;
                searchingData[segmentIndex].old_destinationAirportCode = this.state.searchingData[segmentIndex].destinationAirportCode;
            }
            let index = this.state.editOptionSegments.indexOf(segmentIndex);
            this.state.editOptionSegments.splice(index, 1);
            this.setState({editOptionSegments: this.state.editOptionSegments});
        }
        else if(action === 'date')
        {
            searchingData[segmentIndex].preferred_departure_time = this.changeDateFormat(newDate);
            searchingData[segmentIndex].preferred_arrival_time = this.changeDateFormat(newDate);
            searchingData[segmentIndex].old_preferred_arrival_time = this.changeDateFormat(oldDate);
        }
        else if(action === 'from')
        {
            searchingData[segmentIndex].old_originCityFullName = newDate.labelValue;
            searchingData[segmentIndex].old_originCityName = newDate.cityName;
            searchingData[segmentIndex].old_origin = newDate.newValue;
            searchingData[segmentIndex].old_originCountryName = newDate.countryName;
            searchingData[segmentIndex].old_originAirportCode = newDate.airportCode;
        }
        else if(action === 'to')
        {
            searchingData[segmentIndex].old_destinationCityFullName = newDate.labelValue;
            searchingData[segmentIndex].old_destinationCityName = newDate.cityName;
            searchingData[segmentIndex].old_destination = newDate.newValue;
            searchingData[segmentIndex].old_destinationAirportCode = newDate.airportCode;
            searchingData[segmentIndex].old_destinationCountryName = newDate.countryName;
        }
        this.setState({
            searchingData : searchingData
        })
    }

    /******************************* Get origin cities ***************************************/
    getOriginCitiesByFilter(filterString)
    {
        if(filterString && filterString.length > 1)
        {
            const successCb = () =>{
                let cityArray = []; 
                if(this.props.citiesResponse.response){
                    cityArray = this.props.citiesResponse.response.map(function (cityData, index) {
                             return ({
                                value: cityData.airport_code+' '+cityData.city_code+' '+cityData.city_full_name,//it is used for searching
                                newValue: cityData.airport_code,//set value
                                label:  <React.Fragment> <span className='pull-right'>{cityData.airport_code}</span><samp>{cityData.city_full_name}</samp> </React.Fragment>,
                                airportCode:cityData.airport_code,
                                cityName: cityData.city_name,
                                labelValue:cityData.city_full_name,
                                countryName: cityData.country_name,
                            });
                        })
                }
                this.setState({originCityArray: cityArray});
            }
            if(this.origin_apiTimeout){
                clearInterval(this.origin_apiTimeout)
            }
            this.origin_apiTimeout=setTimeout(() => {
                this.props.getCitiesAction(filterString, successCb); 
            }, 400);
        }
    }

    /******************************** Get destination cities *********************************/
    getDestinationCitiesByFilter(filterString)
    {
        if(filterString && filterString.length > 1)
        {
            const successCb = () =>{
                let cityArray = []; 
                if(this.props.citiesResponse.response){
                    cityArray = this.props.citiesResponse.response.map(function (cityData, index) {
                             return ({
                                value: cityData.airport_code+' '+cityData.city_code+' '+cityData.city_full_name,//it is used for searching
                                newValue: cityData.airport_code,//set value
                                label:  <React.Fragment> <span className='pull-right'>{cityData.airport_code}</span><samp>{cityData.city_full_name}</samp> </React.Fragment>,
                                airportCode:cityData.airport_code,
                                cityName: cityData.city_name,
                                labelValue:cityData.city_full_name,
                                countryName: cityData.country_name,
                            });
                        })
                }
                this.setState({destinationCityArray: cityArray});
            }
            if(this.dest_apiTimeout){
                clearInterval(this.dest_apiTimeout)
            }
            this.dest_apiTimeout=setTimeout(() => {
                this.props.getCitiesAction(filterString, successCb); 
            }, 400);
        }
    }
    /******************************** Set fare type *********************************/
    getSelectedFareType = (event) => {
        this.setState({selectedFareType: event.target.value})
        this.props.setFieldValue('pft', event.target.value);
    }

    componentDidMount()
    {
        this.getOriginCitiesByFilter('default');
        this.getDestinationCitiesByFilter('default');
    }
    isOutsideRange(day){
        if(this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments.length){
            return day.isBefore(moment(this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[this.props.searchQuery.segments.length-1] &&  this.props.searchQuery.segments[this.props.searchQuery.segments.length-1].preferred_departure_time && this.props.searchQuery.segments[this.props.searchQuery.segments.length-1].preferred_departure_time.split('T') && this.props.searchQuery.segments[this.props.searchQuery.segments.length-1].preferred_departure_time.split('T')[0]));
        }else{
            let date1=isStateChange;
        let date2=this.props.values && this.props.values.first_preferred_departure_time && this.props.values.first_preferred_departure_time.split('T') && this.props.values.first_preferred_departure_time.split('T')[0]
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        if (momentDate1.isAfter(momentDate2)) {
            return day.isBefore(moment(isStateChange && isStateChange.split('T') && isStateChange.split('T')[0]))
         } else if (momentDate1.isBefore(momentDate2)) {
            return day.isBefore(moment(this.props.values && this.props.values.first_preferred_departure_time && this.props.values.first_preferred_departure_time.split('T') && this.props.values.first_preferred_departure_time.split('T')[0]))
         } else {
            return day.isBefore(moment(this.props.values && this.props.values.first_preferred_departure_time && this.props.values.first_preferred_departure_time.split('T') && this.props.values.first_preferred_departure_time.split('T')[0]))
         }
        }
    }
    // isOutsideRangeModify(day,ede,edit){
    //     const currentDate = new Date();
    //     const formattedDate = currentDate.toISOString().split('T')[0] + 'T00:00:00';
    //     return day.isBefore(moment(edit > 0 ? ede && ede.preferred_departure_time.split('T')[0] : formattedDate.split('T')[0] )) 

    // }
    isOutsideRangeModify(day, ede, edit, isAfters,finalSearchingData,index) {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0] + 'T00:00:00';
        const specifiedDate = index > 0 ? ede && ede.preferred_departure_time && ede.preferred_departure_time.split('T') && ede.preferred_departure_time.split('T')[0] : formattedDate.split('T')[0];
        let maxSelectable = isAfters && isAfters.preferred_departure_time && isAfters.preferred_departure_time.split('T') && isAfters.preferred_departure_time.split('T')[0];
        if (finalSearchingData.length-1 !== index) {
            maxSelectable = moment(maxSelectable).add(1, 'day').format('YYYY-MM-DD');
            return day.isBefore(moment(specifiedDate)) || day.isAfter(moment(maxSelectable));
        } else {
            return day.isBefore(moment(specifiedDate));
        }
    }

    
    
    componentDidUpdate(prev,next){
        let date1=prev.values.first_preferred_arrival_time;
        let date2=next.searchingData[next.searchingData.length-1] && next.searchingData[next.searchingData.length-1].preferred_departure_time;
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        if(date2){
            if (momentDate1.isAfter(momentDate2)) {
                isStateChange=date1
             } else if (momentDate1.isBefore(momentDate2)) {
                 isStateChange=date2;
             } else {
                 isStateChange=date1
             }
        }
}

    render() {
        let self = this;
        const { allClassesData, setFieldValue, errors, touched ,values} = this.props;
        const { focused, selectedOriginCity, selectedDestinationCity, selectedCabinClass, editOptionSegments, originCityArray, destinationCityArray } = this.state;
        let {selectedAirlines} = this.state;
        /******************************** Map City Array ******************************/ 
        let finalSearchingData = '';
        if(this.props.searchQuery && this.state.searchingData.length <= 0)
        {
            finalSearchingData =  this.props.searchQuery.segments;
        }
        else if(this.state.searchingData.length > 0)
        {
            finalSearchingData = this.state.searchingData;
        }
        
        /******************************** Map All Airlines Array ******************************/ 
        const allAirlinesArray = [];     
        allAirlinesArray.push({
            value: 'GDS',
            label: 'GDS'
        },
        {
            value: '6E',
            label: 'Indigo'
        }); 

        // if(selectedAirlines.length === 0 && !(this.props.searchQuery && this.props.searchQuery.source))
        // {
        //     selectedAirlines = allAirlinesArray;
        // }

        if(this.props.searchQuery && this.props.searchQuery.source){
            selectedAirlines = [];
            for(let i = 0;i< allAirlinesArray.length; i++){
                if(this.props.searchQuery.source.includes(allAirlinesArray[i].value))
                {
                    selectedAirlines.push(allAirlinesArray[i]);
                }                        
            }
        }
        /******************************** Map All Class Array ******************************/            
        let allCabinClassArray = [];
        if(allClassesData.response){
            allCabinClassArray = 
            allClassesData.response.map(function (classData, index) {
                return (
                    {
                        value: classData.class_id,
                        label: classData.flight_class
                    }
                )
            })
        }

        let dateData = '';
         if(this.props.values.segments && this.props.values.segments.length > 0)
        {
            dateData = this.props.values.segments[this.props.values.segments.length-1].preferred_departure_time.split('T');
        }
        else
        {
            dateData = this.props.values && this.props.values.first_preferred_departure_time && this.props.values.first_preferred_departure_time.split('T');
           
        
        }
        let dateCompare1 = moment(isStateChange);
        let dateCompare2 = moment(this.props.values.first_preferred_departure_time);
       
        
        return (
            <div id="multi-city" className="tab_contener">
                <div className="inneer-tab-section multicity-popup">
                    <h2>Book Domestic and International Flights</h2>
                    {/* {errors.multiCityCount && touched.multiCityCount && <span className="error-msg">{errors.multiCityCount}</span>} */}
                    <div className="mid-tab-form multi-city-block">
                    <div className="error-msg-block">
                    {errors.multiCityCount && touched.multiCityCount &&<span className="error-msg">{errors.multiCityCount}</span>}
                    </div>
                        <div className="row-block">
                            <div className="col-row">
                                <div className="form-inner">
                                    <label className="control-label">Form</label>
                                    <div className="control-group select-custom">
                                        <Field
                                            component={Select}
                                            value={selectedOriginCity}
                                            options={originCityArray}
                                            name="origin" 
                                            className="" 
                                            onChange={(event) =>{ this.setOriginCityName(event, setFieldValue)}}
                                            onInputChange={(event) => {this.getOriginCitiesByFilter(event)}}
                                            openMenuOnFocus="true"
                                            //tabSelectsValue="false"
                                            placeholder="From"
                                        />
                                    </div>
                                    {errors.origin && <span className="error-msg">{errors.origin}</span>}
                                </div>
                            </div>
                            {/* -------------------------------------------- City/Trip Direction arrow -----------------------------------Start----- */}
                            <div className="col-mid">
                                <div className="exchange-icon"><i className="fa fa-arrow-right" aria-hidden="true" /></div>
                            </div>
                            {/* --------------------------------------------------- City - To --------------------------------------------Start----- */}
                            <div className="col-row">
                                <div className="form-inner">
                                    <label className="control-label">To</label>
                                    <div className="control-group select-custom">
                                        <Field
                                            component={Select}
                                            value={selectedDestinationCity}
                                            options={destinationCityArray}
                                            name="destination" 
                                            className="" 
                                            onBlur={this.destinationHandleBlur} 
                                            onChange={(event) => {this.setDestinationCityName(event, setFieldValue)}}
                                            onInputChange={(event) => {this.getDestinationCitiesByFilter(event)}}
                                            placeholder="To"
                                            openMenuOnFocus="true"
                                            tabIndex="0"
                                            //tabSelectsValue="false"
                                        >
                                        </Field>
                                    </div>
                                    {errors.destination &&  <span className="error-msg">{errors.destination}</span>}
                                </div>
                            </div>
                            <div className="col-block col-half-date">
                                <div className="form-inner">
                                    <label className="control-label">Depart</label>
                                    <div className="control-group">
                                        <div className="input-group  date dateteam-picker" data-date="Start Date ">
                                            <SingleDatePicker
                                                date={moment(dateData[0])}
                                                onDateChange={date => {
                                                    setFieldValue("first_preferred_departure_time", this.changeDateFormat(date)); 
                                                    setFieldValue("first_preferred_arrival_time", this.changeDateFormat(date)); }}
                                                focused={focused}
                                                onFocusChange={({ focused }) => this.setState({ focused })}
                                                id="start_date"
                                                displayFormat="DD MMM YYYY"
                                                // readOnly={true}
                                                showDefaultInputIcon={true}
                                                inputIconPosition={"after"}
                                                hideKeyboardShortcutsPanel={true}
                                                reopenPickerOnClearDate={true}
                                                withPortal={true}
                                                isOutsideRange={this.isOutsideRange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {dateCompare1.isAfter(dateCompare2) ? 
                            <div className="col-block add-multicity">
                                <button className="add-city"   onClick={() =>{
                                    this.Itinerary(values,selectedOriginCity,selectedDestinationCity)
                                }}> Add to itinerary</button>
                                
                            </div>:<div className="col-block add-multicity">
                                <button className="add-city"   onClick={() =>{
                                    this.addToItinerary(values,selectedOriginCity,selectedDestinationCity)
                                }}>Add to itinerary</button>
                            </div>
                            }
                           {this.state.idDisplayError  &&  <span style={{color:'red'}}>{this.state.idDisplayError}</span>}
                            <div className="multicity-section">
                                <ul className="multicity-list">
                                    
                                    {
                                        finalSearchingData.length > 0 &&
                                        finalSearchingData.map((item, index) => {
                                            let edit = finalSearchingData.findIndex((val) => {
                                                return val.origin === item.origin && val.destination === item.destination
                                            })
                                            if (item.old_destinationCityName && item.old_originCityName && item.old_origin && item.old_destination) {
                                                return (
                                                    <li className='multi-edit-list' key={index}>
                                                        <div className="row">
                                                            <div className="col-multi from">
                                                                {
                                                                    editOptionSegments.indexOf(index) > -1 ?
                                                                        <div className="control-group select-custom">
                                                                            <Field
                                                                                component={Select}
                                                                                value={item.old_originCityName + '(' + item.old_origin + ')'}
                                                                                options={originCityArray}
                                                                                name="origin"
                                                                                className=""
                                                                                onChange={(event) => { self.actionIgnoreSaveSegments(index, 'from', item, event, setFieldValue); }}
                                                                                onInputChange={(event) => { this.getOriginCitiesByFilter(event) }}
                                                                                openMenuOnFocus="true"
                                                                                placeholder={item.old_originCityName + '(' + item.old_origin + ')'}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        <span>{item.old_originCityName + '(' + item.old_origin + ')'}</span>
                                                                }
                                                            </div>
                                                            <div className="col-multi to">
                                                                {
                                                                    editOptionSegments.indexOf(index) > -1 ?
                                                                        <div className="control-group select-custom">
                                                                            <Field
                                                                                component={Select}
                                                                                value={item.old_destinationCityName + '(' + item.old_destination + ')'}
                                                                                options={destinationCityArray}
                                                                                name="destination"
                                                                                className=""
                                                                                onBlur={this.destinationHandleBlur}
                                                                                onChange={(event) => { self.actionIgnoreSaveSegments(index, 'to', item, event, setFieldValue); }}
                                                                                onInputChange={(event) => { this.getDestinationCitiesByFilter(event) }}
                                                                                openMenuOnFocus="true"
                                                                                tabIndex="0"
                                                                                placeholder={item.old_destinationCityName + '(' + item.old_destination + ')'}
                                                                            >
                                                                            </Field>
                                                                        </div> :
                                                                        <span>{item.old_destinationCityName + '(' + item.old_destination + ')'}</span>
                                                                }
                                                            </div>
                                                            <div className="col-multi date-edit">
                                                                {
                                                                    editOptionSegments.indexOf(index) > -1 ?
                                                                        <div className="edit-calendar">
                                                                            <div className="input-group  date dateteam-picker">
                                                                                <SingleDatePicker
                                                                                    date={moment(item.preferred_departure_time)}
                                                                                    onDateChange={date => {
                                                                                        self.actionIgnoreSaveSegments(index, 'date', item.preferred_departure_time, date);
                                                                                    }}
                                                                                    focused={self.state['calenderDate_' + index]}
                                                                                    onFocusChange={({ focused }) => self.setState({ ['calenderDate_' + index]: focused })}
                                                                                    id={"start_date_" + index}
                                                                                    displayFormat="DD MMM YYYY"
                                                                                    // readOnly={true}
                                                                                    showDefaultInputIcon={true}
                                                                                    inputIconPosition={"after"}
                                                                                    hideKeyboardShortcutsPanel={true}
                                                                                    reopenPickerOnClearDate={true}
                                                                                    withPortal={true}
                                                                                    isOutsideRange={(day,) => this.isOutsideRangeModify(day, finalSearchingData[index - 1 <= 0 ? 0 : index - 1], edit, finalSearchingData[edit + 1], finalSearchingData, index)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <span className="date-seat">{dateFormat(item.preferred_departure_time, 'DD MMM YYYY')}</span>
                                                                }
                                                            </div>
                                                            <div className="col-multi multi-btn">
                                                                {
                                                                    editOptionSegments.indexOf(index) > -1 ?
                                                                        <div className="click-multi-edit">
                                                                            <button className="btn-col" onClick={() => { this.actionIgnoreSaveSegments(index, 'save', '', '') }}>Save</button>
                                                                            <button className="btn-col" onClick={() => { this.actionIgnoreSaveSegments(index, 'ignore', '', '') }}>Ignore</button>
                                                                            <button className="btn-col" onClick={() => { this.deleteSegments(index) }}>Delete</button>
                                                                        </div>
                                                                        :
                                                                        <div className="multi-edit">
                                                                            <button className="btn-col" onClick={() => { this.showEditOptions(index) }}>Edit</button>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="col-block">
                                <div className="form-inner">
                                    <label className="control-label">Class</label>
                                    <div className="control-group">
                                        <div className="control-group select-custom">
                                            <Field 
                                                component={Select} 
                                                name="flight_cabin_class"
                                                options={allCabinClassArray}
                                                value={selectedCabinClass}
                                                onChange={(event) =>{ this.setSelectedCabinClass(event, setFieldValue)}}
                                                placeholder="Select Class"  
                                                > 
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-block">
                                <div className="form-inner">
                                    <label className="control-label">Source</label>
                                    <div className="control-group">
                                        <Picky
                                            options={allAirlinesArray}
                                            value={selectedAirlines}
                                            valueKey="value"
                                            labelKey="label"
                                            multiple={true}
                                            includeSelectAll={true}
                                            onChange={(event) =>{ this.setSelectedAirlines(event, setFieldValue)}}
                                            dropdownHeight={600}
                                            placeholder={"Any"}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                                <div className="col-block col-radio">
                                    <div className="form-inner">
                                        <label className="control-label">Select Fare Type</label>
                                        <div className="control-group">
                                            <div className="radio-check inline-check">
                                                <input 
                                                    name="pft" 
                                                    type="radio" 
                                                    id="pft1" 
                                                    onClick={(event) => this.getSelectedFareType(event)}
                                                    className="form-control"
                                                    value="REGULAR" 
                                                    defaultChecked={this.state.selectedFareType === 'REGULAR'? true: false}
                                                />
                                                <label htmlFor="pft1">Regular Fare</label></div><div className="radio-check inline-check label-tooltip student-fare-tooltip">
                                                <input 
                                                    name="pft" 
                                                    type="radio" 
                                                    id="pft2" 
                                                    onClick={(event) => this.getSelectedFareType(event)}
                                                    className="form-control"
                                                    value="STUDENT"
                                                    defaultChecked={this.state.selectedFareType === 'STUDENT'? true: false} 
                                                />
                                                <label htmlFor="pft2">
                                                <span className="">Student Fare
	                                                <div className="info-tooltip">
	                                                	<div className="tooltip-outer">
		                                              <p>Only students above 12 years of age are eligible for special fares and/or additional baggage allowances. Carrying valid student ID cards and student visas (where applicable) is mandatory, else the passenger may be denied boarding or asked to pay for extra baggage.</p>
                                                        </div>
                                                    </div>
                                                </span>
                                                </label></div><div className="radio-check inline-check label-tooltip student-fare-tooltip">
                                                <input 
                                                    name="pft" 
                                                    type="radio" 
                                                    id="pft3" 
                                                    onClick={(event) => this.getSelectedFareType(event)}
                                                    className="form-control"
                                                    value="SENIOR_CITIZEN"
                                                    defaultChecked={this.state.selectedFareType === 'SENIOR_CITIZEN'? true: false} 
                                                />
                                                <label htmlFor="pft3">
                                                <span className="">Senior Citizen Fare
                                                    <div className="info-tooltip">
                                                        <div className="tooltip-outer">
                                                        <p>Only senior citizens above the age of 60 years can avail this special fare. It is mandatory to produce proof of Date of Birth at the airport, without which prevailing fares will be charged.</p>
                                                        </div>
                                                    </div>
                                                </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                            <div className="col-block col-add-block">
                                <div className="col-plus-minus">
                                    <div className="form-inner">
                                        <label className="control-label"> Adult<span className="red-error">*</span> <small>(12+ Yrs)</small></label>
                                        <div className="group-input">
                                            <span onClick={() => this.onAdultClick('sub', setFieldValue)} className={this.state.adultCount !== 0 ? 'bold-active':''}><i className="fa fa-minus" aria-hidden="true" /></span>
                                            <small>{this.state.adultCount}</small>
                                            <span onClick={() => this.onAdultClick('add', setFieldValue)} className={this.state.adultCount < 9 ? 'bold-active':''}><i className="fa fa-plus" aria-hidden="true" /> </span>
                                        </div>
                                        {errors.adult_count && touched.adult_count && <span className="error-msg">{errors.adult_count}</span>}
                                    </div>
                                </div>
                                <div className="col-plus-minus">
                                    <div className="form-inner">
                                        <label className="control-label">Child <small>(2-12 Yrs)</small></label>
                                        <div className="group-input">
                                            <span onClick={() => this.onChildClick('sub', setFieldValue)} className={this.state.childCount !== 0 ? 'bold-active':''}><i className="fa fa-minus" aria-hidden="true" /></span>
                                            <small>{this.state.childCount}</small>
                                            <span onClick={() => this.onChildClick('add', setFieldValue)} className={this.state.childCount < 8 ? 'bold-active':''}><i className="fa fa-plus" aria-hidden="true" /> </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-plus-minus">
                                    <div className="form-inner">
                                        <label className="control-label">Infant <small>(Below 2 Yrs)</small></label>
                                        <div className="group-input">
                                            <span onClick={() => this.onInfantClick('sub', setFieldValue)} className={this.state.infantCount !== 0 ? 'bold-active':''}><i className="fa fa-minus" aria-hidden="true" /></span>
                                            <small>{this.state.infantCount}</small>
                                            <span onClick={() => this.onInfantClick('add', setFieldValue)} className={this.state.infantCount < 8 ? 'bold-active':''}><i className="fa fa-plus" aria-hidden="true" /> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-btn-block">
                        { /* eslint-disable-next-line */}
                        <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            setFieldValue('segments',this.state.searchingData,false);
                            this.props.submitForm();
                        }}
                        className="btn btn-green" >
                        <i className="fa fa-search" aria-hidden="true" />Search</a>
                    </div>
                </div>
            </div>
        )
    }
}



export default FlightMultiCitySearchActiveComponent